import React, { useEffect, useMemo, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { api } from '../../../lib/axios';

import Header from '../../../Components/Header.js';
import iconMoeda from '../../../assets/icons/moeda.png';
import TabelaBms from '../../../Components/Tabelas/TableBMs';
import TabelaServicosSetor from '../../../Components/Tabelas/ServicosBMs';
import BarChart from '../../../Components/Chats/BarChart';
import OverviewTotalProfit from '../../../Components/Tasks/overview-total-profit';
import OverviewTasksProgress from '../../../Components/Tasks/overview-tasks-progress';
import Viewer from '../../../Components/PlugBase/Viewer/Viewer';

import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { default as MaterialUISelect } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';

// import { getDatesInRange } from '../../../utils/date';
// import VerticalBarChart from '../../../Components/Chats/VerticalBarChart';
// import { OverviewCard } from '../../../Components/OverviewCard';

//----------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#313862',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function datesAreEqual(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

function MainFinanceiroPB() {
  //Recebendo a URN do modelo selecionado no dropDown de Contrato e em seguida o texto do elemento
  const [opcoesContrato, setOpcoesContrato] = useState([]);
  const [selectedOption, setSelectedOption] = useState(''); // contrato

  //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
  const [opcoesSetor, setOpcoesSetor] = useState([]);
  const [setorOption, setSetorOption] = useState([]);

  //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
  const [opcoesBMs, setOpcoesBMs] = useState([]);
  const [bmOption, setBmOption] = useState('');

  //Recebendo a data de vigencia referente ao modelo selecionado no dropDown de Contrato
  const [opcoesDataVigencia, setopcoesDataVigencia] = useState([]);

  //Recebendo os dados do BM agrupados para poder atualizar a tabela de BMS
  const [opcoesDataTableBms, setOpcoesDataTableBms] = useState([]);

  //Recebendo os dados do serviços agrupados para poder atualizar a tabela de serviços
  const [opcoesDataTableServicos, setOpcoesDataTableServicos] = useState([]);

  //Recebendo os dados de previsto x executado para atualizar graficos
  const [opcoesPrevistoXExecutado, setOpcoesPrevistoXExecutado] = useState([]);

  const [valoresPrevistoXExecutado, setvaloresPrevistoXExecutado] = useState([]);

  const [setorComProgresso, setSetorComProgresso] = useState([]);

  //--------
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChangeBM = (event) => {
    const bmValue = event.target.value;
    setBmOption(bmValue);
    setMobileOpen(false);
  };

  const [mobileOpen, setMobileOpen] = useState(false);


  const handleSelectChangeContract = (event) => {
    const urnValue = event.target.value;
    setSelectedOption(urnValue);

    api
      .get('/dadossetor?numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
      .then((response) => setOpcoesSetor(response.data));

    fetch(
      'https://plug3dviewerserver.madrix.app/dadosbm?' +
        'numContrato=' +
        urnValue.split('/')[1] +
        '&instancia=plugbase'
    )
      .then((response) => response.json())
      .then((data) => setOpcoesBMs(data));

    api
      .get('/dadosvigencia?numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
      .then((response) => setopcoesDataVigencia(response.data));


    fetch(
      'https://plug3dviewerserver.madrix.app/dadostabelabms?' +
        'numContrato=' +
        urnValue.split('/')[1] +
        '&instancia=plugbase' +
        `&setor=&bm=`
    )
      .then((response) => response.json())
      .then((data) => {
        setOpcoesDataTableBms(data);
        setvaloresPrevistoXExecutado(data.length > 0 ? [data[data.length - 1]] : data);
      });

    fetch(
      'https://plug3dviewerserver.madrix.app/dadostabelaservices?' +
        'numContrato=' +
        urnValue.split('/')[1] +
        '&instancia=plugbase' +
        '&setor=' +
        ''
    )
      .then((response) => response.json())
      .then((data) => setOpcoesDataTableServicos(data));

    fetch(
      'https://plug3dviewerserver.madrix.app/dadoschartdata?' +
        'numContrato=' +
        urnValue.split('/')[1] +
        '&instancia=plugbase'
    )
      .then((response) => response.json())
      .then((data) => setOpcoesPrevistoXExecutado(data));

    setMobileOpen(false);
  };

  useEffect(() => {
    api
      .get('/token/listObjectsPBB?instancia=plugbase')
      .then((response) => setOpcoesContrato(response.data.items));
  }, []);

  useEffect(() => {
    const urnValue = document.querySelector('#contratoIdSelect').innerText + '.nwd';

    setSetorOption(selectedOptions);

    api
      .get(
        '/dadostabelabms?' +
          'numContrato=' +
          urnValue +
          '&setor=' +
          selectedOptions +
          '&bm=' +
          bmOption +
          '&instancia=plugbase'
      )
      .then((response) => {
        setOpcoesDataTableBms(response.data);
        setvaloresPrevistoXExecutado(
          response.data.length > 0 ? [response.data[response.data.length - 1]] : response.data
        );
      });

    api
      .get(
        '/dadostabelaservices?' +
          'numContrato=' +
          urnValue +
          '&setor=' +
          selectedOptions +
          '&instancia=plugbase'
      )
      .then((response) => setOpcoesDataTableServicos(response.data));
  }, [selectedOptions, selectedOption, bmOption]);
  
  useEffect(() => {
    const urnValue = document.querySelector('#contratoIdSelect').innerText + '.nwd';

    if (selectedOptions.length === 0) {
      setSetorComProgresso([]);
    }

    if (selectedOptions.length > 0) {
      setSetorComProgresso([]);
      selectedOptions.forEach((codSetor) => {
        api
          .get(
            '/dadostabelabms2?' +
              'numContrato=' +
              urnValue +
              '&setor=' +
              [codSetor] +
              '&bm=' +
              bmOption +
              '&instancia=plugbase'
          )
          .then((response) => {
            setSetorComProgresso((prevState) => {
              const existsInArray = prevState.findIndex(
                (item) => item.Codigo_WBS === response.data[0].Codigo_WBS
              );

              if (existsInArray === -1) {
                return [...prevState, response.data[0]];
              }

              return prevState;
            });
          });
      });
    }
  }, [selectedOptions, bmOption]);

  // Tabela de Serviços que se adapta de acordo com o BM selecionado
  const opcoesTableServicosComBmSelecionado = useMemo(() => {
    if (!bmOption) {
      return [];
    }
    const bmItem = opcoesDataTableBms?.find((item) => item.id === bmOption);

    let data;
    if (bmOption === '1') {
      // Se for o primeiro BM, filtra por data de inicio e fim
      data = opcoesDataTableServicos?.filter(
        (item) =>
          datesAreEqual(new Date(item?.DataInicio), new Date(bmItem?.DataInicio)) &&
          datesAreEqual(new Date(item?.DataTermino), new Date(bmItem?.DataTermino))
      );
    } else {
      // Se não for o primeiro BM, filtra por data de inicio
      data = opcoesDataTableServicos?.filter((item) => {
        return new Date(item?.DataTermino) <= new Date(bmItem?.DataTermino);
      });
    }

    return data;
  }, [opcoesDataTableServicos, bmOption, opcoesDataTableBms]);

  return (
    <Box sx={{ flexGrow: 1 }}> 

      <Header menuOpen={mobileOpen} setMenuOpen={setMobileOpen}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="setorLabelID" style={{ color: '#fff' }}>
                Setor
              </InputLabel>
              <MaterialUISelect
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important'
                  }
                }}
                labelId="setorLabelID"
                id="setorIdSelect"
                label="Setor"
                multiple
                value={selectedOptions}
                onChange={(event) => setSelectedOptions(event.target.value)}
                renderValue={(selected) => selected.join(', ')}
                style={{ minWidth: '200px' }}>
                {opcoesSetor.map((option) => (
                  <MenuItem key={uuidv4()} value={option.Codigo_WBS}>
                    <Checkbox checked={selectedOptions.indexOf(option.Codigo_WBS) > -1} />
                    <ListItemText
                      primary={
                        String.fromCharCode(8194).repeat(
                          (option.Codigo_WBS.match(/./g) || []).length
                        ) + option.Discriminacao_WBS
                      }
                    />
                  </MenuItem>
                ))}
              </MaterialUISelect>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="bmLabelID" style={{ color: '#fff' }}>
                BM
              </InputLabel>
              <MaterialUISelect
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important'
                  }
                }}
                labelId="bmLabelID"
                id="bmIdSelect"
                label="BM"
                onChange={handleSelectChangeBM}
                style={{ minWidth: '200px' }}
                value={bmOption}>
                <MenuItem key={uuidv4()} label={'Todos'} value={''}>
                  {'Todos'}
                </MenuItem>
                {opcoesBMs.map((opcao) => (
                  <MenuItem key={opcao.BM} label={opcao.BM} value={opcao.BM}>
                    {opcao.BM}
                  </MenuItem>
                ))}
              </MaterialUISelect>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="contratoLabelID" style={{ color: '#fff' }}>
                Contrato
              </InputLabel>
              <MaterialUISelect
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important'
                  }
                }}
                labelId="contratoLabelID"
                id="contratoIdSelect"
                label="Contrato"
                onChange={handleSelectChangeContract}
                style={{ minWidth: '200px' }}
                value={selectedOption}>
                {opcoesContrato.map((opcao) => (
                  //<MenuItem key={uuidv4()} label={opcao.objectKey.slice(0, 8)} value={opcao.objectId} >{opcao.objectKey.slice(0, 8)}</MenuItem>
                  <MenuItem
                    key={uuidv4()}
                    label={opcao.objectKey.slice(0, 8)}
                    value={opcao.objectId}>
                    {opcao.objectKey.slice(0, opcao.objectKey.length - 4)}
                  </MenuItem>
                ))}
              </MaterialUISelect>
            </FormControl>
      </Header>

      <Box mt="76px" display="flex" flexDirection="column" gap="16px">
        <Grid alignItems="stretch" container spacing={2}>
          <Grid sm={6} md={3} xs={12}>
            <OverviewTotalProfit
              sx={{ height: '100%' }}
              value={opcoesDataVigencia.map(
                (opcao) =>
                  `${new Date(opcao.Data_de_Inicio)
                    .toLocaleDateString('pt-BR')
                    .toString()} á ${new Date(opcao.Data_de_Termino_Atual_da_Vigencia)
                    .toLocaleDateString('pt-BR')
                    .toString()}`
              )}
              label="Prazo de Vigência"
              hexcolor="#313862"
              colorIcon="#62C17E"
              icon={iconMoeda}
            />
          </Grid>

          <Grid sm={6} md={3} xs={12}>
            <OverviewTotalProfit
              sx={{ height: '64%' }}
              value={
                `R$ ` +
                valoresPrevistoXExecutado.map((opcao) =>
                  new Intl.NumberFormat('pt-BR').format(opcao.ValorPrevisto_WBS)
                )
              }
              label="Valor Previsto"
              hexcolor="#313862"
              colorIcon="#62C17E"
              icon={iconMoeda}
            />
          </Grid>

          <Grid sm={6} md={3} xs={12}>
            <OverviewTotalProfit
              sx={{ height: '64%' }}
              value={
                `R$ ` +
                valoresPrevistoXExecutado.map((opcao) =>
                  new Intl.NumberFormat('pt-BR').format(opcao.ValorAcumulado_WBS)
                )
              }
              label="Valor Executado"
              hexcolor="#313862"
              colorIcon="#62C17E"
              icon={iconMoeda}
            />
            {/* <OverviewCard hexColor="#313862" label="Valor Executado" icon={iconMoeda} /> */}
          </Grid>

          <Grid sm={6} md={3} xs={12}>
            <OverviewTasksProgress
              sx={{ height: '100%' }}
              value={opcoesDataTableBms}
              label={'Avanço Financeiro'}
              hexcolor="#313862"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid xs={12} md={7.5}>
            <Item className="flex flex-col gap-6 h-[480px] lg:h-full">
              <Viewer selectedContract={selectedOption} selectedSetores={setorComProgresso} />
              <div className="flex justify-center items-center gap-4 pb-1">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-[#62c17e]"></div>
                  <span className="text-sm text-[#62c17e]">Concluído</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-[#FFFF00]"></div>
                  <span className="text-sm text-[#FFFF00]">Em andamento</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-[#ff0000]"></div>
                  <span className="text-sm text-[#ff0000]">A iniciar</span>
                </div>
              </div>
            </Item>
          </Grid>

          <Grid xs={12} md={4.5}>
            {/* <VerticalBarChart
              label="Previsto x Realizado - Execução Financeira"
              opcoesPrevistoXExecutado={opcoesPrevistoXExecutado}
            /> */}
            <Item style={{ height: '60vh' }}>
              <BarChart
                opcoesPrevistoXExecutado={opcoesPrevistoXExecutado}
                altura={'50vh'}
                cabecalho={'Previsto x Realizado - Execução Financeira'}
                modulo={'F'}
                eixo={'x'}
              />
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid sm={12} md={6.5}>
            <Item>
              <TabelaBms altura={'300px'} opcoesDataTableBms={opcoesDataTableBms} />
            </Item>
          </Grid>

          <Grid sm={12} md={5.5}>
            <Item>
              <TabelaServicosSetor
                altura={'300px'}
                opcoesDataTableServicos={
                  bmOption ? opcoesTableServicosComBmSelecionado : opcoesDataTableServicos
                }
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export { MainFinanceiroPB };
