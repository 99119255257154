function Card({ title, value }) {
  return (
    <div className="bg-[#313862] text-white rounded flex flex-col gap-2 justify-center text-center p-3">
      <span className="font-semibold">{title}</span>
      <strong className="text-4xl text-[#a1caeb]">{value}</strong>
    </div>
  );
}

export default Card;
