import { Spinner } from '../../../Components/Spinner';
import { cn } from '../../../lib/utils';

export function MainCard({ label, content, isLoading }) {

  return (
    <div
      className={cn(
        `p-2 bg-apmClaro flex flex-col gap-2 rounded`,
        isLoading && 'items-center justify-center h-[72px]'
      )}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <span>{label}</span>

          <strong>{content}</strong>
        </>
      )}
    </div>
  );
}
