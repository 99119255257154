import {
  launchViewer,
  filterModelBySetorBM,
  filterModelBySetor,
  filterModelByTask
} from './ViewerFunctionCVI';
import React, { useEffect } from 'react';

//const NOP_VIEWER = window.NOP_VIEWER; 

function Viewer({
  selectedSetores,
  selectedContract,
  tipo = 'financeiro',
}) {
  useEffect(() => {
    if (tipo === 'financeiro') {
      filterModelBySetorBM(selectedSetores);
    } else if (tipo === 'qualidade') {
      filterModelBySetor(
        selectedSetores.map((item) => ({
          ...item,
          possuiNaoConformidades: item.naoConformidades.some(
            (naoConformidade) => naoConformidade.Status !== 'Concluído'
          )
        }))
      );
    }
  }, [selectedSetores, tipo]);

  useEffect(() => {
    launchViewer('viewerDiv', selectedContract);
  }, [selectedContract]);

  return <div style={{ position: 'relative', width: '100%', height: '100%' }} id="viewerDiv" />;
}

export default Viewer;
