import { useMediaQuery } from '@mui/material';
import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { mergeArrays } from '../utils/group-by';

export const months = {
  1: 'Jan',
  2: 'Fev',
  3: 'Mar',
  4: 'Abr',
  5: 'Mai',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez'
};

const fullMonths = {
  Jan: 'Janeiro',
  Fev: 'Fevereiro',
  Mar: 'Março',
  Abr: 'Abril',
  Mai: 'Maio',
  Jun: 'Junho',
  Jul: 'Julho',
  Ago: 'Agosto',
  Set: 'Setembro',
  Out: 'Outubro',
  Nov: 'Novembro',
  Dez: 'Dezembro'
};

export function BarLineChart({ previsto, realizado, projecao, selectedTask }) {
  const matches = useMediaQuery('(max-width:550px)');
  const currentMonth = new Date().getMonth();

  const teste = mergeArrays(previsto, realizado);

  const adicionarProjecao = teste.map((item) => ({
    ...item,
    Projecao:
      projecao?.find(
        (itemProjecao) => item.name === `${months[itemProjecao.Mes]} ${itemProjecao.Ano}`
      )?.Quantidade ?? 0
  }));

  const realData = adicionarProjecao.reduce((acc, item, index) => {
    if (index === 0) {
      // Se for o primeiro ponto de dados, apenas retorna o próprio item
      return [
        {
          ...item,
          'Linha de base': item.Previsto, // Define a linha de base como o valor previsto do primeiro mês
          'Realizado acumulado': item.Realizado, // Define o realizado acumulado como o valor realizado do primeiro mês
          Projecao: item.Projecao ?? 0 // Define a projeção como o valor da projeção do primeiro mês
        }
      ];
    } else {
      // Caso contrário, realiza a manipulação dos valores acumulados
      const currentYear = new Date().getFullYear();
      const indexOfCurrentYearProjecao = teste.findIndex(
        (item) => item.name === `${months[currentMonth]} ${currentYear}`
      );
  
      if (item.Previsto === 0) {
        // Se o valor previsto for zero, apenas retorna o item atual sem alterações nos valores acumulados
        return [
          ...acc,
          {
            name: item.name,
            Previsto: item.Previsto,
            Projecao: acc[index - 1].Projecao + item.Projecao ?? 0, // Atualiza a projeção acumulada
            ...((index <= indexOfCurrentYearProjecao || selectedTask) && {
              'Realizado acumulado': acc[index - 1]['Realizado acumulado'] + item.Realizado // Atualiza o realizado acumulado
            })
          }
        ];
      } else {
        // Se o valor previsto for diferente de zero, realiza as atualizações nos valores acumulados
        return [
          ...acc,
          {
            ...item,
            //Previsto: item.Projecao,
            'Linha de base': acc[index - 1]['Linha de base'] + item.Previsto, // Atualiza a linha de base acumulada
            Projecao: acc[index - 1].Projecao + item.Projecao, // Atualiza a projeção acumulada
            ...((index <= indexOfCurrentYearProjecao || selectedTask) && {
              'Realizado acumulado': acc[index - 1]['Realizado acumulado'] + item.Realizado // Atualiza o realizado acumulado
            })
          }
        ];
      }
    }
  }, []);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={realData}>
        <XAxis
          tick={{ fontSize: matches ? 10 : 8 }}
          textAnchor={matches ? 'middle' : 'end'}
          angle={matches ? 0 : -60}
          interval={matches ? 5 : 0}
          stroke="white"
          dataKey="name"
          height={40}
        />
        <YAxis
          tickFormatter={(value) => `${value}%`}
          width={40}
          tick={{ fontSize: 14 }}
          stroke="white"
        />
        <Tooltip
          formatter={(a) => `${a.toFixed(2)}%`}
          labelFormatter={(a) => fullMonths[a.split(' ')[0]] + ' ' + a.split(' ')[1]}
        />
        <Legend />
        <Bar dataKey="Previsto" barSize={20} fill="#B8D8D8" />
        <Bar dataKey="Realizado" barSize={20} fill="#61C0FE" />
        <Line type="monotone" dataKey="Linha de base" strokeWidth={2} stroke="#FDB833" />
        <Line type="monotone" dataKey="Projecao" name="Projeção" strokeWidth={2} stroke="green" />
        <Line type="monotone" dataKey="Realizado acumulado" strokeWidth={2} stroke="red" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

//  ultima data do baseline e timephased

// concluido - atrasado - dentro do prazo

