import {
  launchViewer,
  filterModelBySetorBM,
  filterModelBySetor,
  filterModelByTask,
  filterModelBySetorBraskem
} from './ViewerFunction';
import React, { useEffect } from 'react';

//const NOP_VIEWER = window.NOP_VIEWER; 

function Viewer({
  selectedSetores,
  selectedContract,
  tipo = 'financeiro',
}) {
  useEffect(() => {
    if (tipo === 'financeiro') {
      filterModelBySetorBM(selectedSetores); 
    } else if (tipo === 'qualidade') {
      filterModelBySetor(
        selectedSetores.map((item) => ({
          ...item,
          possuiNaoConformidades: item.naoConformidades.some(
            (naoConformidade) => naoConformidade.Status !== 'Concluído' && naoConformidade.Status !== 'Cancelado'
          )
        }))
      );
    } else if (tipo === 'qualidade_braskem'){
      // filterModelBySetorBraskem(
      //   selectedSetores.map((item) => ({
      //     ...item,
      //     naoConformidades: item.naoConformidades.filter(
      //       (naoConformidade) => naoConformidade.Status !== 'Concluído' && naoConformidade.Status !== 'Cancelado'
      //     ),
      //     possuiNaoConformidades: item.naoConformidades.some(
      //       (naoConformidade) => naoConformidade.Status !== 'Concluído' && naoConformidade.Status !== 'Cancelado'
      //     )
      //   }))
      // );
      filterModelBySetorBraskem(selectedSetores)
    }
  }, [selectedSetores, tipo]);

  useEffect(() => {
    launchViewer('viewerDiv', selectedContract);
  }, [selectedContract]);

  return <div style={{ position: 'relative', width: '100%', height: '100%' }} id="viewerDiv" />;
}

export default Viewer;
