import { useQueries } from '@tanstack/react-query';
import { api } from '../../lib/axios';

export function useDegrees(contrato, tipo, selectedSetores) {
  return useQueries({
    queries: [
      {
        queryKey: [`grau/taxa-nao-aplica/${contrato}/${tipo}/${selectedSetores}`, contrato, tipo, selectedSetores],
        queryFn: async () => {
          const response = await api.get(`braskem/planoDeacao_taxa_nao_se_aplica?numContrato=${contrato}&tipo=${tipo}&setores=${selectedSetores}`);
          return { label: 'Itens que não se Aplicam', value: response.data[0].GRAU_CONFORMIDADE };
        },
        enabled: !!contrato && !!tipo && !!selectedSetores,
        staleTime: 1000 * 60 * 5
      },
      {
        queryKey: [`grau/nao-conformidade/${contrato}/${tipo}/${selectedSetores}`, contrato, tipo, selectedSetores],
        queryFn: async () => {
          const response = await api.get(`braskem/planoDeacao_grau_nconformidade?numContrato=${contrato}&tipo=${tipo}&setores=${selectedSetores}`);
          return { label: 'Não Conformidades', value: response.data[0].GRAU_CONFORMIDADE };
        },
        enabled: !!contrato && !!tipo && !!selectedSetores,
        staleTime: 1000 * 60 * 5
      },
      {
        queryKey: [`grau/conformidade/${contrato}/${tipo}/${selectedSetores}`, contrato, tipo, selectedSetores],
        queryFn: async () => {
          const response = await api.get(`braskem/planoDeacao_grau_conformidade?numContrato=${contrato}&tipo=${tipo}&setores=${selectedSetores}`);
          return { label: 'Conformidades', value: response.data[0].GRAU_CONFORMIDADE };
        },
        enabled: !!contrato && !!tipo && !!selectedSetores,
        staleTime: 1000 * 60 * 5
      }
    ]
  });
}

// useEffect(() => {
//     api
//       .get('/token/listObjectsPBB?instancia=plugbase')
//       .then((response) => setOpcoesContrato(response.data.items));
//   }, []);
