import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { api } from '../../../lib/axios';

import { useContracts } from '../../../hooks/braskem/use-contracts';
import { useDegrees } from '../../../hooks/braskem/use-degrees';
import { useChecklistsQuantity } from '../../../hooks/braskem/use-checklists-quantity';
import { useNaoConformidades } from '../../../hooks/braskem/use-nao-conformidades';
import { useChecklistsUnconformities } from '../../../hooks/braskem/use-checklists-unconformities';
import { useDataByType } from '../../../hooks/braskem/use-data-by-type';

import { groupBy } from '../../../utils/group-by';

import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
 
import { Select } from '../../../Components/Select';
import OverviewTotalProfit from '../../../Components/Tasks/overview-total-profit';
import { TabelaChecklists } from '../../../Components/Tabelas/TableChecklists';
import Viewer from '../../../Components/PlugBase/Viewer/Viewer';
import BarChartNew from '../../../Components/Chats/bar-chart';
import { PizzaChart } from '../../../Components/Chats/PizzaChart';
import { Spinner } from '../../../Components/Spinner';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../Components/Tabs';
import { TabelaPlanoDeAcao } from '../../../Components/Tabelas/TablePlanoDeAcao';
import { QuantityChecklistNcChart } from '../../../Components/Chats/QuantityChecklistNcChart';

import iconQualidade from '../../../assets/icons/qualidade.png';

import { BraskemHeader } from '../BraskemHeader';

import Card from './Card';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#003C7F',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function QualidadeBraskem() {
  const url = useLocation();
  const [tipoDeNaoConformidade, setTipoDeNaoConformidade] = useState('Todos');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedSetores, setSelectedSetores] = useState([]);
  const [setores, setSetores] = useState([]);
  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const [naoConformidadesPorSetor, setNaoConformidadesPorSetor] = useState([]);

  const [descricao, setDescricao] = useState([]);

  const instancia = url.pathname.split('/').slice(1)[0];

  const contratoEObra = selectedOption?.split('/')[1];
  const obra = contratoEObra?.split('_')[1]?.slice(0, -4);

  // Hooks que servem para fazer as requisições para o back-end
  const contractsQuery = useContracts('braskem');
  
  const taxesQueries = useDegrees(selectedOption.split('/')[1], tipoDeNaoConformidade, selectedSetores);
  const dataByTypeQuery = useDataByType(selectedOption.split('/')[1], selectedSetores, selectedStatus);
  const naoConformidadesQuery = useNaoConformidades(selectedOption.split('/')[1], selectedSetores);
  const checklistsQuantityQuery = useChecklistsQuantity(
    selectedOption.split('/')[1],
    selectedSetores
  );
  const checklistsAndUnconformitiesQuery = useChecklistsUnconformities(
    selectedOption.split('/')[1],
    selectedSetores,
    tipoDeNaoConformidade.split(' - ')[0],
    selectedStatus
  );

  const listaDeNaoConformidades = useMemo(
    () => naoConformidadesQuery.data ?? [],
    [naoConformidadesQuery.data]
  );
  const isNaoConformidadesLoading =
    naoConformidadesQuery.isLoading && naoConformidadesQuery.fetchStatus === 'fetching';

  const isLoading = taxesQueries.some(
    (query) => query.isLoading && query.fetchStatus === 'fetching'
  );

  const handleSelectChangeContract = (event) => {
    const urnValue = event.target.value;
    setSelectedOption(urnValue);

    api
      .get('braskem/dadossetor_qualidade?numContrato=' + urnValue.split('/')[1] + '&instancia=braskem')
      .then((response) => setSetores(response.data));

      api
      .get(
          `braskem/valor_descricao?numContrato=${
            urnValue.split('/')[1]
          }`
        )
        .then((response) => {
          setDescricao(response.data[0]);
        });
    

    setMenuMobileOpen(false);
  };

  // Variável que armazena a quantidade de não conformidades
  const quantidadeDeNaoConformidades = useMemo(() => {
    let quantidade;

    // Se existem setores selecionados a forma de filtrar é diferente
    if (selectedSetores.length > 0) {
      // Calcula a quantidade de não conformidades por setor, excluindo as que estão concluídas
      if(tipoDeNaoConformidade !== "Todos"){
        quantidade = naoConformidadesPorSetor
        .map((item) => item.naoConformidades?.filter((item) => item.Status !== 'Concluído' && item.Status !== 'Cancelado' && item.Checklist === tipoDeNaoConformidade).length)
        .reduce((acc, item) => {
          return acc + item;
        }, 0);
      }else{
        quantidade = naoConformidadesPorSetor
        .map((item) => item.naoConformidades?.filter((item) => item.Status !== 'Concluído' && item.Status !== 'Cancelado').length)
        .reduce((acc, item) => {
          return acc + item;
        }, 0);
      }
    } else {
      // Calcula a quantidade de não conformidades, excluindo as que estão concluídas
      if(tipoDeNaoConformidade !== "Todos"){
        quantidade = listaDeNaoConformidades?.filter((item) => item.Status !== 'Concluído' && item.Status !== 'Cancelado' && item.Checklist === tipoDeNaoConformidade).length;
      }else{
        quantidade = listaDeNaoConformidades?.filter((item) => item.Status !== 'Concluído' && item.Status !== 'Cancelado').length;
      } 
    }
    return quantidade;
  }, [naoConformidadesPorSetor, selectedSetores.length, listaDeNaoConformidades, tipoDeNaoConformidade]);

  // Variável que armazena as opções de não conformidades
  const tiposDeNaoConformidade = useMemo(() => {
    if (selectedSetores.length > 0) {
      // Formata as não conformidades por setor em um array único
      const naoConformidadesPorSetorFormatadas = Array.from(
        new Set(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) =>
              item.naoConformidades.map((item) => item.Checklist)
            )
          )
        )
      );

      // Se a array não estiver vazia
      if (naoConformidadesPorSetorFormatadas.length > 0) {
        // Retorna ela
        return naoConformidadesPorSetorFormatadas;
      } else {
        // Retorna array vazia
        return [];
      }
    } else {
      // Retorna as opções de não conformidades a partir da lista de não conformidades
      return Array.from(new Set(listaDeNaoConformidades?.map((item) => item.Checklist)));
    }
  }, [listaDeNaoConformidades, naoConformidadesPorSetor, selectedSetores]);

  // Variável que armazena uma tabela de não conformidades
  const tabelaDeNaoConformidades = useMemo(() => {
    // Se o tipo de não conformidade for diferente de 'Todos'
    if (tipoDeNaoConformidade !== 'Todos') {
      // Se tiver setores selecionados
      if (naoConformidadesPorSetor.length > 0) {
        // Filtra não conformidades por tipo selecionado e mapeia com propriedades adicionais
        return Array.from(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) => item.naoConformidades)
          )
        )
          ?.filter((item) => item.Checklist === tipoDeNaoConformidade)
          .map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
      } else {
        // Filtra não conformidades pelo tipo selecionado
        return listaDeNaoConformidades?.filter((item) => item.Checklist === tipoDeNaoConformidade);
      }
    } else {
      // Se tiver setores selecionados
      if (naoConformidadesPorSetor.length > 0) {
        // Retorna lista de não conformidades por setor
        return Array.from(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) => item.naoConformidades)
          )
        ).map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
      } else {
        // Retorna lista de não conformidades normal 
        return listaDeNaoConformidades;
      }
    }
  }, [naoConformidadesPorSetor, tipoDeNaoConformidade, listaDeNaoConformidades]);

  const naoConformidadesPorStatus = groupBy(tabelaDeNaoConformidades, 'Status');

  // Variável que armazena a tabela de checklists
  const tabelaDeChecklists = useMemo(() => {
    // Se tiver vazio, retorn array vazio
    if (!checklistsQuantityQuery.data) {
      return [];
    }

    // Se o tipo de não conformidade for diferente de 'Todos'
    if (tipoDeNaoConformidade === 'Todos') {
      // Retorna lista de checklistsq
      return checklistsQuantityQuery.data;
    } else {
      // Retorna lista de checklists filtrada pelo tipo de não conformidade
      return checklistsQuantityQuery.data.filter(
        (item) => item.Checklist === tipoDeNaoConformidade
      );
    }
  }, [checklistsQuantityQuery.data, tipoDeNaoConformidade]);

  // Variável que armazena os status disponíveis
  const status = Array.from(new Set(tabelaDeNaoConformidades.map((item) => item.Status))) ?? [];


  // Quando um setor(es) é selecionado, reseta o tipo de não conformidade e o status
  useEffect(() => {
    setTipoDeNaoConformidade('Todos');
    setSelectedStatus([]);

  }, [selectedSetores]);


  // Adiciona no estado a lista de não conformidades por setor quando o setor(es) são selecionados
  useEffect(() => {
    if (selectedSetores.length === 0) {
      const setoresFormatted = setores.map(objeto => objeto.Codigo_WBS)
      api
        .get(
          `braskem/tipos_de_nao_conformidade?numContrato=${
            selectedOption.split('/')[1]
          }&setores=${setoresFormatted[0]}`
        )
        .then((response) => {
          setNaoConformidadesPorSetor(response.data);
        });
      // setNaoConformidadesPorSetor([]);
    }else{
      // Se tiver setores selecionados
      if (selectedSetores.length > 0 && selectedOption) {

        api
          .get(
            `braskem/tipos_de_nao_conformidade?numContrato=${
              selectedOption.split('/')[1]
            }&setores=${selectedSetores}`
          )
          .then((response) => {
            setNaoConformidadesPorSetor(response.data);
          });
      }
    }

    

  }, [selectedSetores, selectedOption, setores]);

  // Funções da header
  const renderSetorOptions = () => {
    return setores.map(option => {
      const indent = String.fromCharCode(8194).repeat((option.Codigo_WBS.length - 7) / 3);
      const discriminacao = option.Discriminacao_WBS.length > 25 ? `${option.Discriminacao_WBS.slice(0, 25)}...` : option.Discriminacao_WBS;
  
      return (
        <MenuItem
          key={option.Codigo_WBS}
          value={option.Codigo_WBS}
          style={{ display: 'flex', alignItems: 'center', padding: '4px 8px' }}
        >
          <Checkbox checked={selectedSetores.indexOf(option.Codigo_WBS) > -1} style={{ padding: '0 8px' }} />
          <Tooltip title={option.Discriminacao_WBS}>
            <ListItemText
              primary={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {`${indent}${discriminacao}`}
                </span>
              }
              style={{ marginLeft: '8px' }}
            />
          </Tooltip>
        </MenuItem>
      );
    });
  };
  const renderTipoNaoConformidadeOptions = () => {
    return tiposDeNaoConformidade.map((option, index) => (
      <MenuItem key={index} label={option} value={option}>
        {option}
      </MenuItem>
    ));
  };
  const renderStatusOptions = () => {
    return status.map((option) => (  
      <MenuItem key={option} label={option} value={option}>
        <Checkbox checked={selectedStatus.indexOf(option) > -1} style={{ padding: '0 8px' }} />
        {option}
      </MenuItem>
    ));
  };
  const renderContractOptions = () => {
    return contractsQuery.data?.map((opcao) => (
      <MenuItem key={opcao.objectId} label={opcao.objectKey.slice(0, 8)} value={opcao.objectId}>
        {opcao.objectKey.slice(0, opcao.objectKey.length - 4)}
      </MenuItem>
    ));
  };

  useEffect(() => {
    if (contractsQuery.data && contractsQuery.data.length > 0) {
      setSelectedOption(contractsQuery.data[0].objectId);
      handleSelectChangeContract({ target: { value: contractsQuery.data[0].objectId } });
    }
  }, [contractsQuery.data]);

  return (
    <div className="bg-braskemAzulClaro">

      <BraskemHeader menuOpen={menuMobileOpen} setMenuOpen={setMenuMobileOpen}>
        <Select
          className="min-w-auto max-w-[200px]"
          label="Setor"
          onChange={(event) => setSelectedSetores(event.target.value)}
          value={selectedSetores}
          multiple
          renderValue={(selected) => selected.join(', ')}
        >
          {renderSetorOptions()}
        </Select>

        <Select
          className="min-w-auto max-w-[150px]"
          label="Tipo"
          onChange={(event) => setTipoDeNaoConformidade(event.target.value)}
          value={tipoDeNaoConformidade}
        >
          <MenuItem label="Todos" value="Todos">
            Todos
          </MenuItem>
          {renderTipoNaoConformidadeOptions()}
        </Select>

        <Select
          className="min-w-auto max-w-[150px]"
          label="Status"
          onChange={(event) => setSelectedStatus(event.target.value)}
          value={selectedStatus}
          multiple
          renderValue={(selected) => selected.join(', ')}
        >
          {/* <MenuItem label="Todos" value="Todos">
            Todos
          </MenuItem> */}
          {renderStatusOptions()}
        </Select>

        <Select 
          className="min-w-auto max-w-[150px]"
          label="Contrato" 
          onChange={handleSelectChangeContract} 
          value={selectedOption}
        >
          {renderContractOptions()}
        </Select>
      </BraskemHeader>

      <div className="mt-16 p-4 space-y-4"> 
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
          <OverviewTotalProfit
            value={descricao?.Objeto_Reduzido ?? 'Indefinido'}
            label="Descrição"
            hexcolor="#003C7F"
            colorIcon="#62C17E"
            icon={iconQualidade}
          />
          <Card
            title="Checklists Preenchidas"
            value={
              selectedStatus.length === 0?(tipoDeNaoConformidade === 'Todos'
                ? checklistsQuantityQuery.data?.length ?? 0
                : checklistsQuantityQuery.data?.filter(item => item.Checklist === tipoDeNaoConformidade).length ?? 0):"-"
            }
            isLoading={
              checklistsQuantityQuery.isLoading &&
              checklistsQuantityQuery.fetchStatus === 'fetching'
            }
          />
          <Card
            title="Grau de Conformidades"
            value={selectedStatus.length === 0?`${((taxesQueries[2].data?.value || 0) * 100).toFixed(2)}%`:"-"}
            isLoading={isLoading}
          />
          <Tooltip title={
            <div style={{ fontSize: '14px', padding: '8px' }}>
              {`Não conformidades em aberto: ${selectedStatus.length === 0? quantidadeDeNaoConformidades : "-"}`}
            </div>
          }>
            <div className="bg-braskemAzulEscuro text-white rounded flex flex-col gap-2 items-center justify-center text-center p-3">
              <Card
                  title="Grau de Não Conformidades"
                  value={selectedStatus.length === 0?`${((taxesQueries[1].data?.value || 0) * 100).toFixed(2)}%`:"-"}
                  isLoading={isLoading}
                />
            </div>
          </Tooltip>
          <Card
            title="Grau de Itens que Não se Aplicam"
            value={selectedStatus.length === 0?`${((taxesQueries[0].data?.value || 0) * 100).toFixed(2)}%`:"-"}
            isLoading={isLoading}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <Item className="flex flex-col gap-6 h-[480px] lg:h-full">
              <Viewer
                tipo="qualidade_braskem"
                selectedContract={selectedOption}
                selectedSetores={
                  selectedStatus.length === 0? naoConformidadesPorSetor 
                  : naoConformidadesPorSetor.map(setorObj => ({
                    ...setorObj,
                    naoConformidades: setorObj.naoConformidades.filter(nc => selectedStatus.includes(nc.Status)),
                    possuiNaoConformidades: setorObj.naoConformidades.some(nc => selectedStatus.includes(nc.Status))
                  }))
                }
              />
              <div className="flex justify-center items-center gap-4 pb-1">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVermelho"></div>
                  <span className="text-sm text-cardVermelho">Não conformes</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVerde"></div>
                  <span className="text-sm text-cardVerde">Conformes</span>
                </div>
              </div>
            </Item>
          </div>
          <div className="flex flex-col gap-4 order-[-1]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <BarChartNew
                label="Não Conformidades por Tipo"
                data={
                  tipoDeNaoConformidade === 'Todos'
                    ? dataByTypeQuery.data
                    : dataByTypeQuery.data?.filter(item => item.CHECKLIST === tipoDeNaoConformidade)
                }
                type="NCPT"
                isLoading={dataByTypeQuery.isLoading && dataByTypeQuery.fetchStatus === 'fetching'}
                color="braskem"
              />
              <PizzaChart 
                  data={selectedStatus.length !== 0? 
                    naoConformidadesPorStatus?.filter((item) => selectedStatus.includes(item.Status))
                    : naoConformidadesPorStatus} 
                  isLoading={isNaoConformidadesLoading} color="braskem" />
              
            </div>

            <div className="text-white">
              <QuantityChecklistNcChart
                data={checklistsAndUnconformitiesQuery.data}
                isLoading={
                  checklistsAndUnconformitiesQuery.isLoading &&
                  checklistsAndUnconformitiesQuery.fetchStatus === 'fetching'
                }
                color="braskem"
              />
            </div>
          </div>
        </div>

        <Tabs defaultValue="checklists">
          <TabsList color="braskem">
            <TabsTrigger color="braskem" value="checklists">Checklists Aplicadas</TabsTrigger>
            <TabsTrigger color="braskem" value="unconformities">Não Conformidades</TabsTrigger>
          </TabsList>
          <div className="bg-braskemAzulEscuro">
          <TabsContent value="checklists">
            <TabelaChecklists
              checklists={tabelaDeChecklists?.map((item) => ({ ...item, id: uuidv4() })) ?? []}
            />
          </TabsContent>
          <TabsContent value="unconformities">
            <TabelaPlanoDeAcao
              opcoesDataTableCheklists={
                selectedStatus.length !== 0
                  ? tabelaDeNaoConformidades?.filter((item) => selectedStatus.includes(item.Status))
                  : tabelaDeNaoConformidades
              }
            />
          </TabsContent>
          </div>
        </Tabs>
      </div>
    </div>
  );
}
export { QualidadeBraskem };
