import { months } from '../Components/BarLineChart';

export function groupBy(arr, property) {
  const teste = arr?.reduce((acc, item) => {
    const existsInArray = acc.findIndex((arrItem) => arrItem[property] === item[property]);

    if (existsInArray > -1) {
      acc[existsInArray].qtds++;
    } else {
      acc.push({
        [property]: item[property],
        qtds: 1
      });
    }

    return acc;
  }, []);

  return teste;
}

export function createTaskTree(data) {
  const levelMap = new Map();
  const root = { children: [] };
  levelMap.set(1, root);

  data.sort((a, b) => a.TaskOutlineNumber.localeCompare(b.TaskOutlineNumber));

  for (const task of data) {
    // const { TaskOutlineLevel, TaskOutlineNumber } = task;
    const { TaskOutlineLevel } = task;
    const parentLevel = TaskOutlineLevel - 1;
    const parentNode = levelMap.get(parentLevel);

    if (parentLevel === 0) {
      root.children.push({ ...task, children: [] });
      levelMap.set(TaskOutlineLevel, root.children[root.children.length - 1]);
    } else {
      if (!parentNode) {
        console.error(`Invalid TaskOutlineLevel: ${TaskOutlineLevel}`);
        continue;
      }
      const newNode = { ...task, children: [] };
      parentNode.children.push(newNode);
      levelMap.set(TaskOutlineLevel, newNode);
    }
  }

  return root.children;
}

export function createTaskTreeAPM(data) {
  const taskMap = new Map();

  // First pass: create a map of tasks using their IDs as keys
  for (const task of data) {
    task.children = [];
    taskMap.set(task.id, task);
  }

  // Second pass: connect each task to its parent and calculate its level
  for (const task of data) {
    const parentId = task.parentId;
    if (parentId !== null && parentId !== undefined) {
      const parentTask = taskMap.get(parentId);
      if (parentTask) {
        parentTask.children.push(task);
        task.level = parentTask.level + 1; // Increment the level from the parent
      } else {
        console.error(`Parent task with ID ${parentId} not found for task with ID ${task.id}`);
      }
    } else {
      task.level = 0; // Top-level task
    }
  }

  // Third pass: find and return top-level tasks
  const rootTasks = [];
  for (const task of data) {
    const parentId = task.parentId;
    if (parentId === null || parentId === undefined) {
      rootTasks.push(task);
    }
  }

  return rootTasks;
}

function updateAssignmentFinishDate(task) {
  if (!task?.AssignmentFinishDate && task?.children && task?.children.length > 0) {
    // Recursively update AssignmentFinishDate for children
    task?.children.forEach((child) => {
      updateAssignmentFinishDate(child);
    });

    // Find the child with the maximum AssignmentFinishDate
    const maxChild = task?.children.reduce((max, child) => {
      if (!max || new Date(child.AssignmentFinishDate) > new Date(max.AssignmentFinishDate)) {
        return child;
      }
      return max;
    }, null);

    // Update AssignmentFinishDate for the current task?
    if (maxChild) {
      task.AssignmentFinishDate = maxChild.AssignmentFinishDate;
    }
  }

  return task; // Return the modified task
}

export function updateAssignmentFinishDates(tasks) {
  if (tasks && tasks?.length > 0) {
    tasks.forEach((task) => {
      updateAssignmentFinishDate(task);
    });
    return tasks;
  } else {
    return [];
  }
}

export function flattenTasks(task, result = []) {
  if (!task) {
    return null;
  }

  const flattenedTask = { ...task };
  delete flattenedTask.children; // Remove the 'children' property

  result.push(flattenedTask);

  if (task.children && task.children.length > 0) {
    task.children.forEach((child) => {
      flattenTasks(child, result);
    });
  }

  return result;
}

export function mergeArrays(previsto, realizado) {
  if (!previsto || !realizado) {
    return [];
  }

  const mergedMap = new Map();

  // Preencher o mapa com valores previstos
  previsto.forEach((item) => {
    const chave = `${item.Ano}-${item.Mes}`;
    mergedMap.set(chave, {
      name: `${months[item.Mes]} ${item.Ano}`,
      Previsto: item.Quantidade,
      Realizado: 0
    });
  });

  // Atualizar o mapa com valores realizados
  realizado.forEach((item) => {
    const chave = `${item.Ano}-${item.Mes}`;
    const mergedItem = mergedMap.get(chave);

    if (mergedItem) {
      mergedItem.Realizado = item.Quantidade;
    } else {
      mergedMap.set(chave, {
        name: `${months[item.Mes]} ${item.Ano}`,
        Previsto: 0,
        Realizado: item.Quantidade
      });
    }
  });

  // Converter o mapa de volta para uma array
  const resultado = Array.from(mergedMap.values());

  return resultado;
}
