import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material';

import Calendar from '../../assets/icons/calendario.png';
import { cn } from '../../lib/utils';

function OverviewTotalProfit(props) {
  const { label, value, hexcolor, colorIcon, icon } = props;

  const fontSize = typeof value === 'number' ? 'lg:text-[24px]' : 'md:text-sm 2xl:text-[15px]';

  return (
    
    <Card
      style={{
        backgroundColor: hexcolor,
        color: '#fff',
        marginTop: 0,
        height: '100%'
      }}>
      <CardContent style={{ padding: 12 }}>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={1}>
          <Stack justifyContent="space-between" spacing={1}>
            <Typography variant="overline">{label}</Typography>
            {label === 'Prazo de Vigência' ? (
              <Typography
                textTransform="uppercase"
                fontSize={'15px'}
                style={{ fontWeight: 'bold', marginTop: '17px' }}>
                {value.length > 0 ? value : 'Indefinido'}
              </Typography>
            ) : (
              <h2 className={cn('font-bold uppercase mt-[17px] ', fontSize)}>{value}</h2>
            )}
          </Stack>
          <Avatar
            sx={{
              backgroundColor: colorIcon,
              height: 56,
              width: 56
            }}>
            {label === 'Prazo de Vigência' ? (
              <img src={Calendar} alt="s" style={{ width: '35px' }} />
            ) : label === 'Descrição' ? (
              <img src={icon} alt="s" style={{ width: '40px' }} />
            ) : (
              <img src={icon} alt="s" style={{ width: '50px' }} />
            )}
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default OverviewTotalProfit;
