import { default as MUISelect } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { cn } from '../lib/utils';

export function Select({ className, label, value, onChange, multiple, renderValue, children }) {

  // const labelId = `${label.toLowerCase()}LabelID`;
  // const selectId = `${label.toLowerCase()}IdSelect`;
  const labelId = `${label}LabelID`;
  const selectId = `${label}IdSelect`;

  const sizeClassName = renderValue ? 'lg:w-[200px]' : 'min-w-[200px]';

  return (
    <FormControl size="small" style={{ color: '#fff' }}>
      <InputLabel id={labelId} style={{ color: '#fff' }}>
        {label}
      </InputLabel>
      <MUISelect
        className={cn(sizeClassName, className)}
        sx={{
          color: 'white',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)'
          },
          '.MuiSvgIcon-root ': {
            fill: 'white !important'
          }
        }}
        labelId={labelId}
        id={selectId}
        multiple={multiple}
        label={label}
        renderValue={renderValue}
        value={value}
        onChange={onChange}
        style={{ color: '#fff' }}>
        {children}
      </MUISelect>
    </FormControl>
  );
}
