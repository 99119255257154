import { Minus, Plus } from 'lucide-react';
import { cn } from '../../../lib/utils';
import Tooltip from '@mui/material/Tooltip';

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month is zero-based
  const year = date.getFullYear();

  // Add leading zero if day or month is less than 10
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
}

function getFormattedData(task) {
  // Initialize variables to store cell values
  let descricao = '';
  let avanco = '';
  let inicio = '';
  let termino = '';

  // Iterate through the cells array to find the cell with the matching columnId
  task.cells.forEach(cell => {
    switch (cell.columnId) {
      case 6244475004211076: 
        descricao = cell.value || ''; 
        break;
      case 2585300306972548: 
        avanco = cell.displayValue || ''; 
        break;
      case 8496274817896324: 
        inicio = formatDate(cell.value) || ''; 
        break;
      case 333500493287300: 
        termino = formatDate(cell.value) || ''; 
        break;
      default:
        break;
    }
  });

  return {
    descricao: descricao,
    avanco: avanco,
    inicio: inicio,
    termino: termino,
  };
}


export function TaskTree({ tasks, openBranches, onToggleBranch, setSelectedTask, selectedTask, dynamicColor }) {

  function handleOpenTaskChildren(task) {
    if (task.children.length === 0) {
      return;
    }
    onToggleBranch(task.id);
  }

  return (
    <ul className={`flex flex-col min-w-[900px] bg-${dynamicColor}`}> 
      {tasks.map((task) => (
        
        <li key={task.id} className="flex flex-col rounded-md">
            <div
              onClick={() => setSelectedTask(task)}
              className={cn(
                'grid grid-cols-[400px_1fr_1fr_1fr_1fr_80px] py-2 gap-2 hover:bg-blue-950/60 px-2',
                selectedTask &&
                  selectedTask.id === task.id &&
                  `bg-${dynamicColor}-950/60 hover:bg-${dynamicColor}-950/90 rounded-md`, selectedTask &&
                  task.level===(selectedTask.level) &&
                  `bg-${dynamicColor}-950/60 hover:bg-${dynamicColor}-950/90 rounded-md`
              )}>
                
              <div
              style={{
                marginLeft: task.level * 20
              }} className="flex items-center gap-2">
                {task.children.length !== 0 &&
                <Tooltip title={ task.children.length !== 0 ? task.children.length + " itens" : null }>
                  <button
                    onClick={(event) => {
                      handleOpenTaskChildren(task);
                      event.stopPropagation();
                    }}
                    className="w-4 h-4 flex items-center justify-center rounded-md border border-slate-400 hover:border-slate-800">
                    {openBranches.includes(task.id) ? <Minus size={8} /> : <Plus size={8} />}
                  </button>
                </Tooltip>}
                <span
                  className={cn(
                    'text-sm text-white',
                    task.level === 0 && 'font-semibold' 
                  )}>
                  {getFormattedData(task).descricao}
                </span>
              </div>
  
              <span className="text-sm text-white">{getFormattedData(task).avanco ?? 0+"%"}</span>
              <span className="text-sm text-white">{getFormattedData(task).inicio ?? 0}</span>
              <span className="text-sm text-white">{getFormattedData(task).termino ?? 0}</span>
            </div>
  
            {openBranches.includes(task.id) && (
              <TaskTree
                tasks={task.children}
                openBranches={openBranches}
                onToggleBranch={onToggleBranch}
                setSelectedTask={setSelectedTask}
                selectedTask={selectedTask}
                dynamicColor={dynamicColor}
              />
            )}

               
        </li>
        
      ))}
    </ul>
  );
}