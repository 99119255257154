import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';


const columns = [
  { field: 'id', headerName: 'BM', width: window.innerWidth*0.05 }, 
  {
    field: 'ValorMedido_WBS',
    headerName: 'Valor Medido',
    type: 'number',
    width: window.innerWidth*0.2, 
    valueGetter: (params) =>
      `R$ ${new Intl.NumberFormat('pt-BR').format(params.row.ValorMedido_WBS)}`
  },
  {
    field: 'ValorAcumulado_WBS',
    headerName: 'Valor Acumulado',
    type: 'number',
    width: window.innerWidth*0.2,
    valueGetter: (params) =>
      `R$ ${new Intl.NumberFormat('pt-BR').format(params.row.ValorAcumulado_WBS)}`
  },
  {
    field: 'ValorPrevisto_WBS',
    headerName: 'Avanço',
    type: 'number',
    width: window.innerWidth*0.15, 
    valueGetter: (params) => {
      if(params.row.ValorPrevisto_WBS !== 0){
        const value = ((params.row.ValorMedido_WBS / params.row.ValorPrevisto_WBS)*100).toFixed(2);
        return `${value}%`;
      }else{
        return `0%`;
      }
    }
  },
  {
    field: 'DataInicio',
    headerName: 'Data Inicio',
    type: 'date',
    width: window.innerWidth*0.15, 
    valueGetter: (params) => {
      const date = new Date(params.row.DataInicio);
      const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return utcDate;
    },
    valueFormatter: (params) => {
      const date = params.value;
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  },
  {
    field: 'DataTermino',
    headerName: 'Data Fim',
    type: 'date',
    width: window.innerWidth*0.15, 
    valueGetter: (params) => {
      const date = new Date(params.row.DataTermino);
      const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return utcDate;
    },
    valueFormatter: (params) => {
      const date = params.value;
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  }
];



export default function TabelaBms(props) {
  return (
    <Box sx={{ height: props.altura, maxHeight: props.altura}}>
      <DataGrid
        style={{ color: '#fff' }}
        rows={props.opcoesDataTableBms}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        disableSelectionOnClick
      />
    </Box>
  );
}
