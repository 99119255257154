import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { api } from '../../../lib/axios';

import { useContracts } from '../../../hooks/cvi/use-contracts';
import { useDegrees } from '../../../hooks/cvi/use-degrees';
import { useChecklistsQuantity } from '../../../hooks/cvi/use-checklists-quantity';
import { useNaoConformidades } from '../../../hooks/cvi/use-nao-conformidades';
import { useChecklistsUnconformities } from '../../../hooks/cvi/use-checklists-unconformities';
import { useDataByType } from '../../../hooks/cvi/use-data-by-type';

import { groupBy } from '../../../utils/group-by';

import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import { Select } from '../../../Components/Select';
import OverviewTotalProfit from '../../../Components/Tasks/overview-total-profit';
import { TabelaChecklists } from '../../../Components/Tabelas/TableChecklists';
import Viewer from '../../../Components/PlugBase/Viewer/ViewerCVI';
import BarChartNew from '../../../Components/Chats/bar-chart';
import Header from '../../../Components/Header';
import { PizzaChart } from '../../../Components/Chats/PizzaChart';
import { Spinner } from '../../../Components/Spinner';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../Components/Tabs';
import { TabelaPlanoDeAcao } from '../../../Components/Tabelas/TablePlanoDeAcao';
import { QuantityChecklistNcChart } from '../../../Components/Chats/QuantityChecklistNcChart';

import iconQualidade from '../../../assets/icons/qualidade.png';

import Card from './Card';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#313862',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function MainQualidadePB() {
  const url = useLocation();
  const [tipoDeNaoConformidade, setTipoDeNaoConformidade] = useState('Todos');
  const [selectedStatus, setSelectedStatus] = useState('Todos');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedSetores, setSelectedSetores] = useState([]);
  const [setores, setSetores] = useState([]);
  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const [naoConformidadesPorSetor, setNaoConformidadesPorSetor] = useState([]);

  const instancia = url.pathname.split('/').slice(1)[0];

  const contratoEObra = selectedOption?.split('/')[1];
  const obra = contratoEObra?.split('_')[1]?.slice(0, -4);

  // Hooks que servem para fazer as requisições para o back-end
  const contractsQuery = useContracts('cvi');
  const taxesQueries = useDegrees(selectedOption.split('/')[1]);
  const dataByTypeQuery = useDataByType(selectedOption.split('/')[1], selectedSetores);
  const naoConformidadesQuery = useNaoConformidades(selectedOption.split('/')[1], selectedSetores);
  const checklistsQuantityQuery = useChecklistsQuantity(
    selectedOption.split('/')[1],
    selectedSetores
  );
  const checklistsAndUnconformitiesQuery = useChecklistsUnconformities(
    selectedOption.split('/')[1],
    selectedSetores,
    tipoDeNaoConformidade.split(' - ')[0]
  );

  const listaDeNaoConformidades = useMemo(
    () => naoConformidadesQuery.data ?? [],
    [naoConformidadesQuery.data]
  );
  const isNaoConformidadesLoading =
    naoConformidadesQuery.isLoading && naoConformidadesQuery.fetchStatus === 'fetching';

  const isLoading = taxesQueries.some(
    (query) => query.isLoading && query.fetchStatus === 'fetching'
  );

  const handleSelectChangeContract = (event) => {
    const urnValue = event.target.value;

    setSelectedOption(urnValue);

    api.get('cvi/dadossetor_qualidade?numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
      .then((response) => {
        setSetores(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados do setor de qualidade:", error);
      });
    setMenuMobileOpen(false);
  };

  // Variável que armazena a quantidade de não conformidades
  const quantidadeDeNaoConformidades = useMemo(() => {
    let quantidade;

    // Se existem setores selecionados a forma de filtrar é diferente
    if (selectedSetores.length > 0) {
      // Calcula a quantidade de não conformidades por setor, excluindo as que estão concluídas
      quantidade = naoConformidadesPorSetor
        .map((item) => item.naoConformidades.filter((item) => item.Status !== 'Concluído').length)
        .reduce((acc, item) => {
          return acc + item;
        }, 0);
    } else {
      // Calcula a quantidade de não conformidades, excluindo as que estão concluídas
      quantidade = listaDeNaoConformidades?.filter((item) => item.Status !== 'Concluído').length;
    }

    return quantidade;
  }, [naoConformidadesPorSetor, selectedSetores, listaDeNaoConformidades]);

  // Variável que armazena as opções de não conformidades
  const tiposDeNaoConformidade = useMemo(() => {
    if (selectedSetores.length > 0) {
      // Formata as não conformidades por setor em um array único
      const naoConformidadesPorSetorFormatadas = Array.from(
        new Set(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) =>
              item.naoConformidades.map((item) => item.Checklist)
            )
          )
        )
      );

      // Se a array não estiver vazia
      if (naoConformidadesPorSetorFormatadas.length > 0) {
        // Retorna ela
        return naoConformidadesPorSetorFormatadas;
      } else {
        // Retorna array vazia
        return [];
      }
    } else {
      // Retorna as opções de não conformidades a partir da lista de não conformidades
      return Array.from(new Set(listaDeNaoConformidades?.map((item) => item.Checklist)));
    }
  }, [listaDeNaoConformidades, naoConformidadesPorSetor, selectedSetores]);

  // Variável que armazena uma tabela de não conformidades
  const tabelaDeNaoConformidades = useMemo(() => {
    // Se o tipo de não conformidade for diferente de 'Todos'
    if (tipoDeNaoConformidade !== 'Todos') {
      // Se tiver setores selecionados
      if (naoConformidadesPorSetor.length > 0) {
        // Filtra não conformidades por tipo selecionado e mapeia com propriedades adicionais
        return Array.from(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) => item.naoConformidades)
          )
        )
          .filter((item) => item.Checklist === tipoDeNaoConformidade)
          .map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
      } else {
        // Filtra não conformidades pelo tipo selecionado
        return listaDeNaoConformidades?.filter((item) => item.Checklist === tipoDeNaoConformidade);
      }
    } else {
      // Se tiver setores selecionados
      if (naoConformidadesPorSetor.length > 0) {
        // Retorna lista de não conformidades por setor
        return Array.from(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) => item.naoConformidades)
          )
        ).map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
      } else {
        // Retorna lista de não conformidades normal 
        return listaDeNaoConformidades;
      }
    }
  }, [naoConformidadesPorSetor, tipoDeNaoConformidade, listaDeNaoConformidades]);
  const naoConformidadesPorStatus = groupBy(tabelaDeNaoConformidades, 'Status');

  // Variável que armazena a tabela de checklists
  const tabelaDeChecklists = useMemo(() => {
    // Se tiver vazio, retorn array vazio
    if (!checklistsQuantityQuery.data) {
      return [];
    }

    // Se o tipo de não conformidade for diferente de 'Todos'
    if (tipoDeNaoConformidade === 'Todos') {
      // Retorna lista de checklistsq
      return checklistsQuantityQuery.data;
    } else {
      // Retorna lista de checklists filtrada pelo tipo de não conformidade
      return checklistsQuantityQuery.data.filter(
        (item) => item.Checklist === tipoDeNaoConformidade
      );
    }
  }, [checklistsQuantityQuery.data, tipoDeNaoConformidade]);

  // Variável que armazena os status disponíveis
  const status = Array.from(new Set(tabelaDeNaoConformidades.map((item) => item.Status))) ?? [];

  // Quando um setor(es) é selecionado, reseta o tipo de não conformidade e o status
  useEffect(() => {
    setTipoDeNaoConformidade('Todos');
    setSelectedStatus('Todos');
  }, [selectedSetores]);

  // Adiciona no estado a lista de não conformidades por setor quando o setor(es) são selecionados
  useEffect(() => {
    if (selectedSetores.length === 0) {
      setNaoConformidadesPorSetor([]);
    }

    // Se tiver setores selecionados
    if (selectedSetores.length > 0 && selectedOption) {
      api
        .get(
          `cvi/tipos_de_nao_conformidade?numContrato=${
            selectedOption.split('/')[1]
          }&setores=${selectedSetores}`
        )
        .then((response) => {
          setNaoConformidadesPorSetor(response.data);
        });
    }
  }, [selectedSetores, selectedOption]);

  useEffect(() => {
    if (contractsQuery.data && contractsQuery.data.length > 0) {
      setSelectedOption(contractsQuery.data[0].objectId);
      handleSelectChangeContract({ target: { value: contractsQuery.data[0].objectId } });
    }
  }, [contractsQuery.data]);

  return (
    <div className="bg-plugEscuro">

      <Header menuOpen={menuMobileOpen} setMenuOpen={setMenuMobileOpen}>
        <Select
          className="min-w-auto max-w-[200px]"
          label="Setor"
          onChange={(event) => setSelectedSetores(event.target.value)}
          value={selectedSetores}
          multiple
          renderValue={(selected) => selected.join(', ')}>
          {setores.map((option) => (
            <MenuItem key={option.Codigo_WBS} value={option.Codigo_WBS}>
              <Checkbox checked={selectedSetores.indexOf(option.Codigo_WBS) > -1} />
              <ListItemText
                primary={
                  String.fromCharCode(8194).repeat((option.Codigo_WBS.match(/./g) || []).length) +
                  option.Discriminacao_WBS
                }
              />
            </MenuItem>
          ))}
        </Select>

        <Select
          className="min-w-auto max-w-[150px]"
          label="Tipo"
          onChange={(event) => setTipoDeNaoConformidade(event.target.value)}
          value={tipoDeNaoConformidade}>
          <MenuItem label="Todos" value="Todos">
            Todos
          </MenuItem>
          {tiposDeNaoConformidade.map((option, index) => (
            <MenuItem key={index} label={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>

        <Select
          className="min-w-auto max-w-[150px]"
          label="Status"
          onChange={(event) => setSelectedStatus(event.target.value)}
          value={selectedStatus}
          >
          <MenuItem label="Todos" value="Todos">
            Todos
          </MenuItem>
          {status?.map((option) => (
            <MenuItem key={option} label={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>

        <Select 
          className="min-w-auto max-w-[150px]"
          label="Contrato" 
          onChange={handleSelectChangeContract} 
          value={selectedOption}>
          {contractsQuery.data?.map((opcao) => (
            <MenuItem
              key={opcao.objectId}
              label={opcao.objectKey.slice(0, 8)}
              value={opcao.objectId}>
              {opcao.objectKey.slice(0, opcao.objectKey.length - 4)}
            </MenuItem>
          ))}
        </Select>
        
      </Header>

      <div className="mt-16 p-4 space-y-4"> 
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
          <OverviewTotalProfit
            value={
              setores?.find((item) => item.Codigo_WBS === obra)?.Discriminacao_WBS ?? 'Indefinido'
            }
            label="Descrição"
            hexcolor="#313862"
            colorIcon="#62C17E"
            icon={iconQualidade}
          />
          <Card
            title="Checklists Preenchidas"
            value={checklistsQuantityQuery.data?.length ?? 0}
            isLoading={
              checklistsQuantityQuery.isLoading &&
              checklistsQuantityQuery.fetchStatus === 'fetching'
            }
          />
          <Card
            title="Grau de Conformidades"
            value={`${taxesQueries[2].data?.value?.toFixed(2) * 100 || 0}%`}
            isLoading={isLoading}
          />
          <Tooltip
            style={{
              padding: 16
            }}
            title={`Não conformidades em aberto: ${quantidadeDeNaoConformidades}`}>
            <div className="bg-plugClaro text-white rounded flex flex-col gap-2 items-center justify-center text-center p-3">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <span className="font-semibold">Grau de Não Conformidades</span>
                  <strong className="text-4xl text-plugEspecial">{`${
                    taxesQueries[1].data?.value?.toFixed(2) * 100 || 0
                  }%`}</strong>
                </>
              )}
            </div>
          </Tooltip>
          <Card
            title="Grau de Itens que não se Aplicam"
            value={`${((taxesQueries[0].data?.value?.toFixed(2) * 100)|| 0).toFixed(0) || 0}%`}
            isLoading={isLoading}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <Item className="flex flex-col gap-6 h-[480px] lg:h-full">
              <Viewer
                tipo="qualidade"
                selectedContract={selectedOption}
                selectedSetores={naoConformidadesPorSetor}
              />
              <div className="flex justify-center items-center gap-4 pb-1">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVermelho"></div>
                  <span className="text-sm text-cardVermelho">Não conformes</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVerde"></div>
                  <span className="text-sm text-cardVerde">Conformes</span>
                </div>
              </div>
            </Item>
          </div>
          <div className="flex flex-col gap-4 order-[-1]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <BarChartNew
                label="Não Conformidades por Tipo"
                data={dataByTypeQuery.data}
                type="NCPT"
                isLoading={dataByTypeQuery.isLoading && dataByTypeQuery.fetchStatus === 'fetching'}
              />
              <PizzaChart data={naoConformidadesPorStatus} isLoading={isNaoConformidadesLoading} />
            </div>

            <div className="text-white">
              <QuantityChecklistNcChart
                data={checklistsAndUnconformitiesQuery.data}
                isLoading={
                  checklistsAndUnconformitiesQuery.isLoading &&
                  checklistsAndUnconformitiesQuery.fetchStatus === 'fetching'
                }
              />
            </div>
          </div>
        </div>

        <Tabs defaultValue="checklists">
          <TabsList>
            <TabsTrigger value="checklists">Checklists Aplicadas</TabsTrigger>
            <TabsTrigger value="unconformities">Não Conformidades</TabsTrigger>
          </TabsList>
          <TabsContent value="unconformities">
            <TabelaPlanoDeAcao
              opcoesDataTableCheklists={
                selectedStatus !== 'Todos'
                  ? tabelaDeNaoConformidades.filter((item) => item.Status === selectedStatus)
                  : tabelaDeNaoConformidades
              }
            />
          </TabsContent>
          <TabsContent value="checklists">
            <TabelaChecklists
              checklists={tabelaDeChecklists?.map((item) => ({ ...item, id: uuidv4() })) ?? []}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
export { MainQualidadePB };
