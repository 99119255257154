import Client from '../../../Autenticate/Auth';
//import * as THREE from "three";
//import {ObjectsApi, Autodesk } from 'forge-apis'; 

const Autodesk = window.Autodesk;
const THREE = window.THREE;
// const green = new THREE.Color(255, 255, 0);

var getToken = { accessToken: Client.getAccesstoken() };

//var viewer;
var viewer = window.NOP_VIEWER;

function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function filterModelBySetorBM(setor) {
  if (setor.length === 0) {
    try {
      viewer?.clearThemingColors();
    } catch (error) {
      console.log(error);
    }
  }

  const green = new THREE.Color(0x62c17e);

  setor.forEach((codigo_valor) => {
    try {
      viewer.search(
        codigo_valor.Codigo_WBS,
        (e) => {

          // viewer.select(e, Autodesk.Viewing.SelectionMode.REGULAR);
          // viewer.isolate(e); // Armazena o elemento encontrado no array
          // viewer.clearThemingColors();
          // viewer.setSelectionColor(yellow);

          if (codigo_valor.Divisao_Acumulado_Previsto === 0) {
            e.forEach((dbId) =>
              viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true)
            );
          } else if (
            codigo_valor.Divisao_Acumulado_Previsto > 0 &&
            codigo_valor.Divisao_Acumulado_Previsto < 100
          ) {
            e.forEach((dbId) =>
              viewer.setThemingColor(dbId, new THREE.Vector4(green.r, green.g, green.b), null, true)
            );
          } else {
            e.forEach((dbId) =>
              viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true)
            );
          }
        },
        (error) => {
          console.log({ error });
        }
      );
    } catch (error) {}
  });
}

export function filterModelBySetor(setor) {
  if (setor.length === 0) {
    try {
      viewer?.clearThemingColors();
    } catch (error) {
      console.log(error);
    }
  }

  try {
    viewer.clearThemingColors();
  } catch (error) {}

  const green = new THREE.Color(0x62c17e);

  setor.forEach((item) => {
    try {
      viewer.search(
        item.setor,
        (e) => {
          if (item.possuiNaoConformidades) {
            e.forEach((dbId) =>
              viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true)
            );
          } else {
            e.forEach(
              (dbId) =>
                // viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true)
                viewer.setThemingColor(
                  dbId,
                  new THREE.Vector4(green.r, green.g, green.b),
                  null,
                  true
                )
              // viewer.setThemingColor(dbId, new THREE.Vector4(98, 193, 126, 0), null, true)
            );
          }
        },
        (error) => {
          console.log({ error });
        }
      );
    } catch (error) {}
  });
}

export function filterModelByTask(tasks, selectedTasks, selectedType, extraData) {
  if (tasks.length === 0) {
    try {
      viewer?.clearThemingColors();
    } catch (error) {
      console.log(error);
    }
  }

  if (selectedTasks === null) {
    try {
      viewer?.isolate([]);
    } catch (error) {
      console.log(error);
    }
  }

  try {
    viewer?.clearThemingColors();
  } catch (error) {}

  tasks.forEach((item) => {
    try {
      const WPSCODE = item.cells.find(cell => cell.columnId === 2622271385456516 && cell.hasOwnProperty('value'));
      const AVANCO = item.cells.find(cell => cell.columnId === 2585300306972548 && cell.hasOwnProperty('value'));
      const DATATERMINO = item.cells.find(cell => cell.columnId === 333500493287300 && cell.hasOwnProperty('value'));

        if (WPSCODE) {
          viewer.search(
            WPSCODE.value,
            (e) => {
              if (selectedType === 'avanco') {
                if (AVANCO.value === 0) {
                  //green
                  e?.forEach((dbId) => {
                    viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true);
                  });
                } else if (AVANCO.value > 0 && AVANCO.value < 1) {
                  //yellow
                  e?.forEach((dbId) => {
                    viewer.setThemingColor(dbId, new THREE.Vector4(255, 255, 0), null, true);
                  });
                } else {
                  //red
                  e?.forEach((dbId) => {
                    viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
                  });
                }
                
              } else {
                const currentDate = new Date().toISOString();
                
                if (AVANCO.value === 1) {
                  //green
                  e?.forEach((dbId) => {
                    viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
                  });
                } else if (
                  AVANCO.value < 1 && (Date.parse(DATATERMINO.value) < Date.parse(currentDate))
                ) {
                  //red
                  e.forEach((dbId) => {
                    viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true);
                  });
                } else if (AVANCO.value < 1 && Date.parse(DATATERMINO.value) >= Date.parse(currentDate)) {
                  //yellow
                  e?.forEach((dbId) => {
                    viewer.setThemingColor(dbId, new THREE.Vector4(255, 255, 0), null, true);
                  });
                }
              }
            },
            (error) => {
              console.log({ error: 'oi' });
            }
          );
        }


      
    } catch (error) {
      console.log('joao');
    }
  });

  if (selectedTasks && selectedTasks.length > 0) {
    selectedTasks.forEach((item) => {
      try {
        const WPSCODE = item.cells.find(cell => cell.columnId === 2622271385456516 && cell.hasOwnProperty('value'));
        if (WPSCODE) {
          viewer.search(
            WPSCODE.value,
            (e) => {

              if (e.length > 0) {
                viewer.isolate(e);
                viewer.fitToView(e);
              }
            },
            (error) => {}
          );
        }
      } catch (error) {
        console.log('[SELECTED_TASKS_VIEWER_ERROR]', error);
      }
    });
  }
}

function launchViewer(div, urn) {
  const objectId = utf8_to_b64(urn);
  //console.log(objectId);

  getToken.accessToken.then((token) => {
    var options = {
      env: 'AutodeskProduction',
      accessToken: token.access_token
    };

    Autodesk.Viewing.Initializer(options, function () {
      document.getElementById(div).innerHTML = '';
      var htmlDiv = document.getElementById(div);
      viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
      var startedCode = viewer.start();
      if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
      }

      console.log('Initialization complete, loading a model next...');
    });

    var documentId = 'urn:' + objectId;
    Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

    function onDocumentLoadSuccess(viewerDocument) {
      var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
      viewer.loadDocumentNode(viewerDocument, defaultModel);
    }

    function onDocumentLoadFailure() {
      console.error('Failed fetching Forge manifest');
    }
  });
}

export { launchViewer, filterModelBySetorBM };
