import { useQuery } from '@tanstack/react-query';
import { api } from '../lib/axios';

export function useChecklistsUnconformities(contrato, selectedSetores, tipoDeNaoConformidade) {
  return useQuery({
    queryKey: [
      `checklists-e-nao-conformidades/qtds/${contrato}/${selectedSetores}/${tipoDeNaoConformidade}`,
      contrato,
      selectedSetores,
      tipoDeNaoConformidade
    ],
    queryFn: async () => {
      const tipo = tipoDeNaoConformidade.split(' - ')[0];


      if (tipoDeNaoConformidade === 'Todos') {
        const response = await api.get(
          `/dadoschecklist_qtds_data?numContrato=${contrato}&setores=${selectedSetores}&instancia=plugbase`
        );
        return response.data;
      } else {
        const response = await api.get(
          `/dadoschecklist_qtds_data?numContrato=${contrato}&setores=${selectedSetores}&tipo=${tipo.replaceAll(
            ' ',
            'espaco'
          )}&instancia=plugbase`
        );

        return response.data;
      }

    },
    enabled: !!contrato,
    staleTime: 1000 * 60 * 5
  });
}
