import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { api } from '../../../lib/axios';
import { Select } from '../../../Components/Select';

import OverviewTotalProfit from '../../../Components/Tasks/overview-total-profit';
import { TabelaChecklists } from '../../../Components/Tabelas/TableChecklists';
import Viewer from '../../../Components/PlugBase/Viewer/Viewer';
import { v4 as uuidv4 } from 'uuid';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import iconQualidade from '../../../assets/icons/qualidade.png';

import ListItemText from '@mui/material/ListItemText';

import Checkbox from '@mui/material/Checkbox';
import Card from './Card';
import BarChartNew from '../../../Components/Chats/bar-chart';
import Header from '../../../Components/Header';
import { useMemo } from 'react';
import { PizzaChart, statusData } from '../../../Components/Chats/PizzaChart'; 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#313862',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default function BanabuiuQualidade() {
  const [tipoDeNaoConformidade, setTipoDeNaoConformidade] = useState('Todos');

  const [selectedStatus, setSelectedStatus] = useState('Todos');
  //Recebendo a URN do modelo selecionado no dropDown de Contrato e em seguida o texto do elemento
  const [opcoesContrato, setOpcoesContrato] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  const [selectedSetores, setSelectedSetores] = useState([]);

  //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
  const [setores, setSetores] = useState([]);

  //Recebendo a data de vigencia referente ao modelo selecionado no dropDown de Contrato
  const [opcoesDataVigencia, setopcoesDataVigencia] = useState([]);

  //Recebendo os dados do serviços agrupados para poder atualizar a tabela de planos de ação
  const [listaDeNaoConformidades, setListaDeNaoConformidades] = useState([]);

  //Recebendo os dados do serviços agrupados com informações de quantidades
  const [quantidadeDeChecklists, setQuantidadeDeChecklists] = useState(null);

  //Recebendo os dados do serviços agrupados por data para poder atualizar a tabela de planos de ação
  const [naoConformidadesPorTipo, setNaoConformidadesPorTipo] = useState([]);

  //Recebendo os dados do serviços agrupados por data com informações de quantidades
  const [opcoesCheklistsAgrupadosPorData, setOpcoesCheklistsAgrupadosPorData] = useState([]);

  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const [naoConformidadesPorSetor, setNaoConformidadesPorSetor] = useState([]);

  const [grauDeNaoConformidade, setGrauDeNaoConformidade] = useState();
  const [grauDeConformidade, setGrauDeConformidade] = useState();
  const [grauDeTaxaQueNaoAplica, setGrauDeTaxaNaoAplica] = useState();

  const handleSelectChangeContract = (event) => {
    const urnValue = event.target.value;
    setSelectedOption(urnValue);

    api
      .get('/dadossetor_qualidade?numContrato=' + urnValue.split('/')[1] + '&instancia=plugbase')
      .then((response) => setSetores(response.data));

    setMenuMobileOpen(false);
  };

  useEffect(() => {
    api
      .get('/token/listObjectsPBB?instancia=banabuiu')
      .then((response) => setOpcoesContrato(response.data.items));
  }, []);

  useEffect(() => {
    if (selectedOption) {
      const tipo = tipoDeNaoConformidade.split(' - ')[0];

      if (tipoDeNaoConformidade === 'Todos') {
        api
          .get(
            `/dadoschecklist_qtds_data?numContrato=${
              selectedOption.split('/')[1]
            }&setores=${selectedSetores}&instancia=plugbase`
          )
          .then((response) => setOpcoesCheklistsAgrupadosPorData(response.data));
      } else {
        api
          .get(
            `/dadoschecklist_qtds_data?numContrato=${
              selectedOption.split('/')[1]
            }&setores=${selectedSetores}&tipo=${tipo.replaceAll(' ', 'espaco')}&instancia=plugbase`
          )
          .then((response) => setOpcoesCheklistsAgrupadosPorData(response.data));
      }
    }
  }, [selectedOption, selectedSetores, tipoDeNaoConformidade]);

  useEffect(() => {
    if (selectedOption) {
      api
        .get(`/planoDeacao_grau_nconformidade?numContrato=${selectedOption.split('/')[1]}`)
        .then((response) => setGrauDeNaoConformidade(response.data[0].GRAU_CONFORMIDADE));
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption) {
      api
        .get(`/planoDeacao_grau_conformidade?numContrato=${selectedOption.split('/')[1]}`)
        .then((response) => setGrauDeConformidade(response.data[0].GRAU_CONFORMIDADE));
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption) {
      api
        .get(`/planoDeacao_taxa_nao_se_aplica?numContrato=${selectedOption.split('/')[1]}`)
        .then((response) => setGrauDeTaxaNaoAplica(response.data[0].GRAU_CONFORMIDADE));
    }
  }, [selectedOption]);

  // useEffect(() => {
  //   if (selectedSetores.length === 0) {
  //     setNaoConformidadesPorSetor([]);
  //   }

  //   if (selectedSetores.length > 0 && selectedOption) {
  //     api
  //       .get(
  //         `/tipos_de_nao_conformidade?numContrato=${
  //           selectedOption.split('/')[1]
  //         }&setores=${selectedSetores}`
  //       )
  //       .then((response) => {
  //         setNaoConformidadesPorSetor(response.data);
  //       });
  //   }
  // }, [selectedSetores, selectedOption]);

  // useEffect(() => {
  //   if (selectedOption) {
  //     api
  //       .get(
  //         `dadoschecklist?numContrato=${
  //           selectedOption.split('/')[1]
  //         }&instancia=plugbase&setores=${selectedSetores}`
  //       )
  //       .then((response) => {
  //         setQuantidadeDeChecklists(response.data.length);
  //       });
  //   }
  // }, [selectedOption, selectedSetores]);

  useEffect(() => {
    if (selectedOption) {
      api
        .get(
          `dadoschecklist_planoacao?numContrato=${
            selectedOption.split('/')[1]
          }&instancia=plugbase&setores=${selectedSetores}`
        )
        .then((response) => {
          setListaDeNaoConformidades(
            response.data.map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }))
          );
        });
    }
  }, [selectedOption, selectedSetores]);

  useEffect(() => {
    if (selectedOption) {
      api
        .get(
          `/planoDeacao_qtds_data?numContrato=${selectedOption.split('/')[1]}&instancia=plugbase`
        )
        .then((response) => {
          setNaoConformidadesPorTipo(response.data);
        });
    }
  }, [selectedOption]);

  const quantidadeDeNaoConformidades = useMemo(() => {
    let quantidade;

    if (selectedSetores.length > 0) {
      quantidade = naoConformidadesPorSetor
        .map((item) => item.naoConformidades.filter((item) => item.Status !== 'Concluído').length)
        .reduce((acc, item) => {
          return acc + item;
        }, 0);
    } else {
      quantidade = listaDeNaoConformidades.filter((item) => item.Status !== 'Concluído').length;
    }

    return quantidade;
  }, [naoConformidadesPorSetor, selectedSetores.length, listaDeNaoConformidades]);

  const quantidadeDeNaoConformidadesConcluidas = useMemo(() => {
    let quantidade = 0;

    if (selectedSetores.length > 0) {
      quantidade = naoConformidadesPorSetor
        .map((item) => ({
          ...item,
          naoConformidades: item.naoConformidades.filter((item) => item.Status === 'Concluído')
        }))
        .map((item) => item.naoConformidades.length)
        .reduce((acc, item) => {
          return acc + item;
        }, 0);
    } else {
      quantidade = listaDeNaoConformidades.filter((item) => item.Status === 'Concluído').length;
    }

    return quantidade;
  }, [naoConformidadesPorSetor, selectedSetores.length, listaDeNaoConformidades]);

  const contratoEObra = selectedOption?.split('/')[1];
  const obra = contratoEObra?.split('_')[1]?.slice(0, -4);

  const opcoesDeNaoConformidades = useMemo(() => {
    if (selectedSetores.length > 0) {
      const naoConformidadesPorSetorFormatadas = Array.from(
        new Set(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) =>
              item.naoConformidades.map((item) => item.Checklist)
            )
          )
        )
      );
      if (naoConformidadesPorSetorFormatadas.length > 0) {
        return naoConformidadesPorSetorFormatadas;
      } else {
        return [];
      }
    } else {
      return Array.from(new Set(listaDeNaoConformidades.map((item) => item.Checklist)));
    }
  }, [listaDeNaoConformidades, naoConformidadesPorSetor, selectedSetores]);

  const tabelaDeNaoConformidades = useMemo(() => {
    if (tipoDeNaoConformidade !== 'Todos') {
      if (naoConformidadesPorSetor.length > 0) {
        return Array.from(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) => item.naoConformidades)
          )
        )
          .filter((item) => item.Checklist === tipoDeNaoConformidade)
          .map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
      } else {
        return listaDeNaoConformidades.filter((item) => item.Checklist === tipoDeNaoConformidade);
      }
    } else {
      if (naoConformidadesPorSetor.length > 0) {
        return Array.from(
          [].concat.apply(
            [],
            naoConformidadesPorSetor.map((item) => item.naoConformidades)
          )
        ).map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
      } else {
        return listaDeNaoConformidades;
      }
    }
  }, [naoConformidadesPorSetor, tipoDeNaoConformidade, listaDeNaoConformidades]);

  useEffect(() => {
    setTipoDeNaoConformidade('Todos');
    setSelectedStatus('Todos');
  }, [selectedSetores]);

  // const naoConformidadesPorTipoCorreto = useMemo(() => {
  //   let naoConformidades = [];
  //   if (naoConformidadesPorSetor.length > 0) {
  //     naoConformidades = Array.from(
  //       [].concat.apply(
  //         [],
  //         naoConformidadesPorSetor.map((item) => item.naoConformidades)
  //       )
  //     ).map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
  //   } else {
  //     naoConformidades = listaDeNaoConformidades;
  //   }
  //   const naoConformidadesPorTipoCorreto = naoConformidades?.reduce((acc, item) => {
  //     const existsInArray = acc.findIndex((arrItem) => arrItem.Checklist === item.Checklist);

  //     if (existsInArray > -1) {
  //       acc[existsInArray].qtds++;
  //     } else {
  //       acc.push({
  //         Checklist: item?.Checklist,
  //         qtds: 1
  //       });
  //     }

  //     return acc;
  //   }, []);

  //   return naoConformidadesPorTipoCorreto;
  // }, [listaDeNaoConformidades, naoConformidadesPorSetor]);
  const naoConformidadesPorTipoCorreto = tabelaDeNaoConformidades?.reduce((acc, item) => {
    const existsInArray = acc.findIndex((arrItem) => arrItem.Checklist === item.Checklist);

    if (existsInArray > -1) {
      acc[existsInArray].qtds++;
    } else {
      acc.push({
        Checklist: item?.Checklist,
        qtds: 1
      });
    }

    return acc;
  }, []);

  const naoConformidadesPorStatus = tabelaDeNaoConformidades?.reduce((acc, item) => {
    const existsInArray = acc.findIndex((arrItem) => arrItem.Status === item.Status);

    if (existsInArray > -1) {
      acc[existsInArray].qtds++;
    } else {
      acc.push({
        Status: item?.Status,
        qtds: 1
      });
    }

    return acc;
  }, []);

  const status = Array.from(new Set(tabelaDeNaoConformidades.map((item) => item.Status))) ?? [];

  return (
    <div>
      <Header menuOpen={menuMobileOpen} setMenuOpen={setMenuMobileOpen}>
        <Select
          label="Setor"
          multiple
          value={selectedSetores}
          onChange={(event) => setSelectedSetores(event.target.value)}
          renderValue={(selected) => selected.join(', ')}>
          {setores.map((option) => (
            <MenuItem key={option.Codigo_WBS} value={option.Codigo_WBS}>
              <Checkbox checked={selectedSetores.indexOf(option.Codigo_WBS) > -1} />
              <ListItemText
                primary={
                  String.fromCharCode(8194).repeat((option.Codigo_WBS.match(/./g) || []).length) +
                  option.Discriminacao_WBS
                }
              />
            </MenuItem>
          ))}
        </Select>

        <Select
          label="Tipo"
          value={tipoDeNaoConformidade}
          onChange={(event) => setTipoDeNaoConformidade(event.target.value)}>
          <MenuItem label="Todos" value="Todos">
            Todos
          </MenuItem>
          {opcoesDeNaoConformidades.map((option, index) => (
            <MenuItem key={index} label={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>

        <Select
          value={selectedStatus}
          onChange={(event) => setSelectedStatus(event.target.value)}
          label="Status">
          <MenuItem label="Todos" value="Todos">
            Todos
          </MenuItem>
          {status?.map((option) => (
            <MenuItem key={option} label={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>

        <Select label="Contrato" onChange={handleSelectChangeContract} value={selectedOption}>
          {opcoesContrato.map((opcao) => (
            <MenuItem
              key={opcao.objectId}
              label={opcao.objectKey.slice(0, 8)}
              value={opcao.objectId}>
              {opcao.objectKey.slice(0, opcao.objectKey.length - 4)}
            </MenuItem>
          ))}
        </Select>
      </Header>

      <div className="mt-[56px] p-4 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <OverviewTotalProfit
            value={
              setores?.find((item) => item.Codigo_WBS === obra)?.Discriminacao_WBS ?? 'Indefinido'
            }
            label="Descrição"
            hexcolor="#313862"
            colorIcon="#62C17E"
            icon={iconQualidade}
          />
          <Card title="Quantidade de Checklists" value={quantidadeDeChecklists ?? 0} />

          <Card title="Quantidade de Não Conformidades" value={quantidadeDeNaoConformidades} />
          <Card
            title="Quantidade de Não Conformidades Concluídas"
            value={quantidadeDeNaoConformidadesConcluidas}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <Item className="flex flex-col gap-6 h-[480px] lg:h-full">
              <Viewer
                tipo="qualidade"
                selectedContract={selectedOption}
                selectedSetores={naoConformidadesPorSetor}
              />
              <div className="flex justify-center items-center gap-4 pb-1">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-[#ff0000]"></div>
                  <span className="text-sm text-[#ff0000]">Com não conformidades</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-[#00ff00]"></div>
                  <span className="text-sm text-[#00ff00]">Sem não conformidades</span>
                </div>
              </div>
            </Item>
          </div>
          <div className="flex flex-col lg:grid lg:grid-rows-2 gap-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <BarChartNew
                label="Quantidade de Checklists e NCs/Mês"
                data={opcoesCheklistsAgrupadosPorData}
                type="QC"
              />
              <BarChartNew
                label="Não Conformidades por Tipo"
                data={naoConformidadesPorTipoCorreto}
                type="QNC"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-white">
              <PizzaChart data={naoConformidadesPorStatus} />

              <div className="grid grid-rows-3 gap-4">
                <Card
                  title="Grau de Itens que não se Aplicam"
                  value={`${grauDeTaxaQueNaoAplica?.toFixed(2) * 100 || 0}%`}
                />
                <Card
                  title="Grau de Não Conformidades"
                  value={`${grauDeNaoConformidade?.toFixed(2) * 100 || 0}%`}
                />
                <Card
                  title="Grau de Conformidades"
                  value={`${grauDeConformidade?.toFixed(2) * 100 || 0}%`}
                />
              </div>
            </div>
          </div>
        </div>

        <TabelaChecklists
          altura={'300px'}
          opcoesDataTableCheklists={
            selectedStatus !== 'Todos'
              ? tabelaDeNaoConformidades.filter((item) => item.Status === selectedStatus)
              : tabelaDeNaoConformidades
          }
        />
      </div>
    </div>
  );
}
