import { useQuery } from '@tanstack/react-query';
import { api } from '../lib/axios';

export function useDataByType(contrato, selectedSetores) {
  return useQuery({
    queryKey: [`dados/qtds/${contrato}/${selectedSetores}`, contrato, selectedSetores],
    queryFn: async () => {
      const response = await api.get(
        `/conformes_checklist?numContrato=${contrato}&instancia=plugbase&setores=${selectedSetores}`
      );
      return response.data;
    },
    enabled: !!contrato,
    staleTime: 1000 * 60 * 5
  });
}
