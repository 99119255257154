import axios from 'axios';

const environment = {
  development: 'http://localhost:8085',
  production: 'https://plug3dviewerserver.madrix.app'
};

export const api = axios.create({
  baseURL: environment.production
});
