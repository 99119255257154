import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

function formatDate(date) {
  if (date) {
    return new Intl.DateTimeFormat('pt-BR').format(new Date(date));
  } else {
    return '';
  }
}

const columns = [
  {
    field: 'Cod_Avaliacao',
    renderHeader: () => <div style={{ textAlign: 'center' }}>Cod. de avaliação</div>,
    width: 130
    // // width: window.innerWidth * 0.1
  },
  {
    field: 'Unidade',
    renderHeader: () => <div style={{ textAlign: 'center' }}>Unidade</div>,
    type: 'string',
    width: 230
    // width: window.innerWidth * 0.2
  },
  {
    field: 'Tipo_de_Unidade',
    renderHeader: () => <div style={{ textAlign: 'center' }}>Tipo de Unidade</div>,
    type: 'string',
    width: 230
    // width: window.innerWidth * 0.2
  },
  {
    field: 'Checklist',
    renderHeader: () => <div style={{ textAlign: 'center' }}>Checklist</div>,
    type: 'string',
    width: 580
    // width: window.innerWidth * 0.1
  },
  {
    field: 'Data_Inicial',
    renderHeader: () => <div style={{ textAlign: 'center' }}>Data Inicial</div>,
    type: 'string',
    // width: window.innerWidth * 0.1,
    align: 'center',
    valueGetter: (params) => formatDate(params.row.Data_Inicial),
  },
  {
    field: 'Data_Final',
    renderHeader: () => <div style={{ textAlign: 'center' }}>Data Final</div>,
    type: 'string',
    valueGetter: (params) => formatDate(params.row.Data_Final),
    // width: window.innerWidth * 0.1
  }
];

export function TabelaChecklists({ checklists }) {
  return (
    <Box style={{ height: 300 }}>
      <DataGrid
        style={{ color: '#fff' }}
        rows={checklists}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
