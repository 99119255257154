import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/axios';

export function useChecklistsUnconformities(contrato, selectedSetores, tipoDeNaoConformidade, status) {
  return useQuery({
    queryKey: [
      `checklists-e-nao-conformidades/qtds/${contrato}/${selectedSetores}/${tipoDeNaoConformidade}/${status}`,
      contrato,
      selectedSetores,
      tipoDeNaoConformidade,
      status
    ],
    queryFn: async () => {
      const tipo = tipoDeNaoConformidade.split(' - ')[0];

      if (tipoDeNaoConformidade === 'Todos') {
        const response = await api.get(
          `braskem/dadoschecklist_qtds_data?numContrato=${contrato}&setores=${selectedSetores}&status=${status}`
        );

        return response.data;
      } else {
        const response = await api.get(
          `braskem/dadoschecklist_qtds_data?numContrato=${contrato}&setores=${selectedSetores}&tipo=${tipo.replaceAll(
            ' ',
            'espaco'
          )}&status=${status}`
        );

        return response.data;
      }
    },
    enabled: !!contrato && !!tipoDeNaoConformidade && !!selectedSetores && !!status,
    staleTime: 1000 * 60 * 5
  });
}
