import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/axios';

export function useContracts(instancia) {
  return useQuery({
    queryKey: [`contracts/${instancia}`, instancia],
    queryFn: async () => {
      const response = await api.get(`/token/listObjectsPBB?instancia=${instancia}`);
      return response.data.items;
    },
    staleTime: 1000 * 60 * 5
  });
}
