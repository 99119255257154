import { api } from '../lib/axios'; 

async function getAccesstoken() {
  return api
    .get('/token')
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

const Client = { getAccesstoken };

export default Client;
