import { useQueries } from '@tanstack/react-query';
import { api } from '../lib/axios';

export function useDegrees(contrato) {
  return useQueries({
    queries: [
      {
        // Grau de Conformidades
        queryKey: [`grau/conformidade/${contrato}`, contrato],
        queryFn: async () => {
          const response = await api.get(`/planoDeacao_grau_conformidade?numContrato=${contrato}`);
          return { label: 'Conformidades', value: response.data[0].GRAU_CONFORMIDADE };
        },
        enabled: !!contrato,
        staleTime: 1000 * 60 * 5
      },
      {
        // Grau de Não Conformidades
        queryKey: [`grau/nao-conformidade/${contrato}`, contrato],
        queryFn: async () => {
          const response = await api.get(`/planoDeacao_grau_nconformidade?numContrato=${contrato}`);
          return { label: 'Não Conformidades', value: response.data[0].GRAU_CONFORMIDADE };
        },
        enabled: !!contrato,
        staleTime: 1000 * 60 * 5
      },
      {
        // Grau de Itens que não se Aplicam
        queryKey: [`grau/taxa-nao-aplica/${contrato}`, contrato],
        queryFn: async () => {
          const response = await api.get(`/planoDeacao_taxa_nao_se_aplica?numContrato=${contrato}`);
          return { label: 'Itens que não se Aplicam', value: response.data[0].GRAU_CONFORMIDADE };
        },
        enabled: !!contrato,
        staleTime: 1000 * 60 * 5
      }
    ]
  });
}

// useEffect(() => {
//     api
//       .get('/token/listObjectsPBB?instancia=plugbase')
//       .then((response) => setOpcoesContrato(response.data.items));
//   }, []);
