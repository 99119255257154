import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { Spinner } from '../Spinner';

export const statusData = [
  'Concluído', // verde
  'Cancelado', // vermelho
  'Aguardando preenchimento',
  'Aguardando solução',
  'Solução em análise',
  'Solução reprovada',
  '-',
  'Preenchimento em análise',
  'Preenchimento reprovado',
  'Aguardando conclusão'
];

const colors = {
  'Concluído': '#A6FF96',
  'Cancelado': '#FF0000',
  'Aguardando preenchimento': '#0088FE',
  'Aguardando Solução': '#b0e0e6',
  'Solução em Análise': '#FFBB28',
  'Solução reprovada': '#FF8042'
};

const textClass = {
  'Concluído': 'text-[#A6FF96] text-sm',
  'Cancelado': 'text-[#FF0000] text-sm',
  'Aguardando preenchimento': 'text-[#0088FE] text-sm',
  'Aguardando Solução': 'text-[#b0e0e6] text-sm',
  'Solução em Análise': 'text-[#FFBB28] text-sm',
  'Solução reprovada': 'text-[#FF8042] text-sm'
};

const colorsClass = {
  'Concluído': 'bg-[#A6FF96] w-4 h-4',
  'Cancelado': 'bg-[#FF0000] w-4 h-4',
  'Aguardando preenchimento': 'bg-[#0088FE] w-4 h-4',
  'Aguardando Solução': 'bg-[#b0e0e6] w-4 h-4',
  'Solução em Análise': 'bg-[#FFBB28] w-4 h-4',
  'Solução reprovada': 'bg-[#FF8042] w-4 h-4'
};

export function PizzaChart({ data, isLoading, color }) {
  const statusWithData = new Set(data.filter((item) => item.qtds > 0).map((item) => item.Status));

  return (
    <div className={`rounded p-4 w-full ${color === 'braskem' ? 'bg-[#003C7F]' : 'bg-[#313862]'}`}>
      
      <div className="flex flex-col justify-center gap-2 w-full h-full">   
        <h3 className="text-base font-medium text-center text-white">
            Quantidade de Não Conformidades por Status
        </h3>
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Spinner className="text-white w-20 h-20 flex" />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={220}>
            <PieChart>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={
                  data.length > 0
                    ? data.map((item) => ({ name: item.Status, value: item.qtds }))
                    : [{ name: 'Indefinido', value: 100 }]
                }
                cx="50%"
                cy="50%"
                fill="gray">
                {data.map((item, index) => (
                  <Cell key={`cell-${index}`} fill={colors[item.Status]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        )}

        <div className="grid grid-cols-2 gap-3">
          {Object.entries(colors).map(([name]) => (
            <div
              key={name}
              className="flex items-center gap-2"
              style={{ display: statusWithData.has(name) ? 'flex' : 'none' }}
            >
              <div className={colorsClass[name]}></div>
              <span className={textClass[name]}>{name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
