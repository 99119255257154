import { QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import Router from './routes';
import { queryClient } from './lib/react-query';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
