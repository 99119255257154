// import React from 'react';
// import { Avatar, Box, Card, CardContent, Stack, Typography, Tooltip } from '@mui/material';
// import iconBar from '../../assets/icons/barsChart.png';
// import './index.css';

// function OverviewTasksProgress(props) {
//   const { label, value, sx, hexcolor } = props;

//   let acumulado = 0;
//   let previsto = 100;
//   let medido = 0;

//   try {
//     acumulado = parseFloat(value[value.length - 1].ValorAcumulado_WBS);
//     previsto = parseFloat(value[value.length - 1].ValorPrevisto_WBS);
//     medido = parseFloat(value[value.length - 1].Total_Previsto_Ate_Atual);
//   } catch (error) {}


//   const avancoFinanceiro = parseFloat(((acumulado / previsto) * 100).toFixed(2));
//   const avancoPrevisto = parseFloat(((medido / previsto) * 100).toFixed(2));

//   const containerStyle = {
//     position: 'relative',
//     height: '5px', // Altura da barra ajustada
//     width: '100%',
//     backgroundColor: 'rgba(255, 255, 255)',
//     borderRadius: '5px',
//     overflow: 'hidden',
//   };

//   const financialStyle = {
//     position: 'absolute',
//     height: '100%',
//     width: `${avancoFinanceiro}%`,
//     backgroundColor: '#a1caeb', 
//     zIndex: avancoFinanceiro <= avancoPrevisto ? 2 : 1, // Define a ordem de renderização
//   };

//   const predictedStyle = {
//     position: 'absolute',
//     height: '100%',
//     width: `${avancoPrevisto}%`,
//     backgroundColor: '#66c182', 
//     zIndex: avancoPrevisto < avancoFinanceiro ? 2 : 1, // Define a ordem de renderização
//   };

//   const markerFinStyle = {
//     position: 'absolute',
//     top: '50%', 
//     right: 0,
//     transform: 'translateY(-50%)', 
//     width: '6px',
//     height: '6px',
//     backgroundColor: '#5fb5f6', 
//     borderRadius: '50%', 
//     border: '2px solid #5fb5f6', 
//   };

//   const markerPredStyle = {
//     position: 'absolute',
//     top: '50%', 
//     right: 0,
//     transform: 'translateY(-50%)', 
//     width: '6px',
//     height: '6px',
//     backgroundColor: '#389c56', 
//     borderRadius: '50%', 
//     border: '2px solid #389c56', 
//   };

//   return (
//     <Card
//       style={{
//         backgroundColor: hexcolor,
//         color: '#fff',
//         marginTop: 0,
//         height: '100%',
//       }}
//     >
//       <CardContent>
//         <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={1}>
//           <Stack spacing={0}>
//             <Typography gutterBottom variant="overline">
//               {label}
//             </Typography>
//             <Typography variant="h6">
//               {avancoFinanceiro}%
//             </Typography>
//           </Stack>
//           <Avatar
//             sx={{
//               backgroundColor: '#62C17E',
//               height: 56,
//               width: 56,
//             }}
//           >
//             <img src={iconBar} alt="s" style={{ width: '40px' }} />
//           </Avatar>
//         </Stack>
//         <Box sx={{ mt: 2, width: '100%' }}>
//           <Box sx={{ mt: 1 }}>
//             <div style={containerStyle}>
//               <Tooltip title={`Avanço Financeiro: ${avancoFinanceiro}%`} arrow>
//                 <div style={financialStyle}>
//                   <div style={markerFinStyle}></div> {/* Marker for Avanço Financeiro */}
//                 </div>
//               </Tooltip>
//               <Tooltip title={`Avanço Previsto: ${avancoPrevisto}%`} arrow>
//                 <div style={predictedStyle}>
//                   <div style={markerPredStyle}></div> {/* Marker for Avanço Previsto */}
//                 </div>
//               </Tooltip>
//             </div>
//           </Box>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// }

// export default OverviewTasksProgress;


import React from 'react';
import { Avatar, Box, Card, CardContent, Stack, Typography, Tooltip } from '@mui/material';
import iconBar from '../../assets/icons/barsChart.png';
import './index.css';

function OverviewTasksProgress(props) {
  const { label, value, sx, hexcolor } = props;

  let acumulado = 0;
  let previsto = 100;
  let medido = 0;

  try {
    acumulado = parseFloat(value[value.length - 1].ValorAcumulado_WBS);
    previsto = parseFloat(value[value.length - 1].ValorPrevisto_WBS);
    medido = parseFloat(value[value.length - 1].Total_Previsto_Ate_Atual);
  } catch (error) {}

  const avancoFinanceiro = parseFloat(((acumulado / previsto) * 100).toFixed(2));
  const avancoPrevisto = parseFloat(((medido / previsto) * 100).toFixed(2));

  const containerStyle = {
    position: 'relative',
    height: '7px', // Adjusted bar height
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255)',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  const financialStyle = {
    position: 'absolute',
    height: '100%',
    width: `${avancoFinanceiro}%`,
    backgroundColor: '#66c182', // Green color for financial progress
    zIndex: 1, // Ensure this is behind the marker
  };

  const markerStyle = {
    position: 'absolute',
    height: '100%',
    width: '3px', // Width of the marker line
    backgroundColor: '#07457e', // Blue color for the marker a1caeb
    left: `${avancoPrevisto}%`,
    zIndex: 2, // Ensure this is on top
  };

  return (
    <Card
      style={{
        backgroundColor: hexcolor,
        color: '#fff',
        marginTop: 0,
        height: '100%',
      }}
    >
      <CardContent>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={1}>
          <Stack spacing={0}>
            <Typography gutterBottom variant="overline">
              {label}
            </Typography>
            <Typography variant="h6">
              {avancoFinanceiro}%
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: '#62C17E',
              height: 56,
              width: 56,
            }}
          >
            <img src={iconBar} alt="s" style={{ width: '40px' }} />
          </Avatar>
        </Stack>
        <Box sx={{ mt: 2, width: '100%' }}>
          <Box sx={{ mt: 1 }}>
            <div style={containerStyle}>
            <Tooltip title={<> {`Avanço Financeiro: ${avancoFinanceiro}%`} <br /> {`Avanço Previsto: ${avancoPrevisto}%`} </>} arrow>
                <div style={financialStyle}></div>
              </Tooltip>
              <Tooltip title={`Avanço Previsto: ${avancoPrevisto}%`} arrow>
                <div style={markerStyle}></div>
              </Tooltip>
            </div>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default OverviewTasksProgress;

