import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/axios';
import { v4 as uuidv4 } from 'uuid';

export function useNaoConformidades(contrato, selectedSetores) {
  return useQuery({
    queryKey: [`nao-conformidades/${contrato}/${selectedSetores}`, contrato, selectedSetores],
    queryFn: async () => {
      const response = await api.get(
        `braskem/dadoschecklist_planoacao?numContrato=${contrato}&instancia=braskem&setores=${selectedSetores}`
      );

      return response.data.map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
    },
    enabled: !!contrato,
    staleTime: 1000 * 60 * 5
  });
}
