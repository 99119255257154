import { MainFinanceiroPB } from './FinanceiroPBHome';

function HomeFinanceiroPB() {
  return (
    <div style={{ margin: '15px' }}>
      <MainFinanceiroPB />
    </div>
  );
}

export default HomeFinanceiroPB;
