import React, { memo } from 'react';

import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  indexAxis: 'x',
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        color: 'white'
      }
    }
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        color: '#fff'
      },
      stacked: false
    },
    y: {
      ticks: {
        beginAtZero: true,
        color: '#fff'
      },
      stacked: false
    }
  }
};

function BarChart(props) {
  const data = {
    dataPrevisto: [],
    dataExecutado: [],
    labels: []
  };

  const data2 = {
    labels: data.labels,
    datasets: []
  };

  if (props.modulo === 'F') {
    {
      props.opcoesPrevistoXExecutado.map(
        (opcao) => (
          data.labels.push(opcao.MesAno.split('-')[1] + '/' + opcao.MesAno.split('-')[0]),
          data.dataPrevisto.push(opcao.PREVISTO),
          data.dataExecutado.push(opcao.REALIZADO)
        )
      );
    }

    data2.datasets = [
      {
        label: 'Previsto',
        data: data.dataPrevisto,
        backgroundColor: 'rgba(98, 193, 126, 1)',
        borderColor: 'rgba(98, 193, 126, 1)',
        borderWidth: 1
      },

      {
        label: 'Realizado',
        type: 'bar',
        data: data.dataExecutado,
        backgroundColor: '#A1CAEB',
        borderColor: '#A1CAEB',
        borderWidth: 1
      }
    ];
  } else if (props.modulo === 'QC') {
    {
      props.opcoesCheklistsAgrupadosPorData.map(
        (opcao) => (
          data.labels.push(opcao.MESES.split('-')[1] + '/' + opcao.MESES.split('-')[0]),
          data.dataPrevisto.push(opcao.QTD_CHECKLISTS),
          data.dataExecutado.push(opcao.QTDS_PLANOACAO)
        )
      );
    }

    data2.datasets = [
      {
        label: 'Checklists',
        data: data.dataPrevisto,
        backgroundColor: 'rgba(98, 193, 126, 1)',
        borderColor: 'rgba(98, 193, 126, 1)',
        borderWidth: 1
      },

      {
        label: 'Não Conformidades',
        type: 'bar',
        data: data.dataExecutado,
        backgroundColor: '#A1CAEB',
        borderColor: '#A1CAEB',
        borderWidth: 1
      }
    ];

    options.scales.x.stacked = true;

    options.scales.y.stacked = true;

    options.plugins.legend.display = true;
  } else if (props.modulo === 'QNC') {
    {
      props.opcoesPlanoDeAcaoAgrupadoPorData.map(
        (opcao) => (data.labels.push(opcao.Checklist), data.dataPrevisto.push(opcao.qtds))
      );
    }

    data2.datasets = [
      {
        label: 'Não Conformidade1',
        data: data.dataPrevisto,
        backgroundColor: 'rgba(98, 193, 126, 1)',
        borderColor: 'rgba(98, 193, 126, 1)',
        borderWidth: 1
      }
    ];
  }

  options.indexAxis = props.eixo;

  return (
    <div className="flex flex-col gap-2 w-full" style={{ height: props.altura }}>
      <h3 className="text-base font-medium text-white">{props.cabecalho}</h3>
      {/* Scrollable Chart */}
      <div style={{ overflowX: 'auto', height: '100%' }}>
        <div style={{ width: data.labels.length * 40, height: '100%' }}>
          <Bar data={data2} options={options} />
        </div>
      </div>
      {/* Fixed Legend */}
      <div className="flex justify-center">
        {data2.datasets.map((dataset, index) => (
          <div key={index} className="flex items-center mr-4">
            <div
              style={{ backgroundColor: dataset.backgroundColor, width: 20, height: 20, marginRight: 5 }}
            ></div>
            <span className="text-white">{dataset.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
  
  
}

export default memo(BarChart);
